import useTypedSelector from '../../../../hooks/use-typed-selector'
import {callHomeRequestsSelector} from '../../../../store/state/call-home-request/selectors'
import {CallHomeRequests} from '../../../../values/call-home-request'
import {ChrTablePopulated} from './chr-list-table-populated'
import {NoDataTable} from './no-data-table/no-data-table'

export function ChrTableLoaded(): JSX.Element {
    const dataCHR = useTypedSelector(callHomeRequestsSelector)

    const open: Array<CallHomeRequests> = dataCHR.filter(
        (chr) => chr.status == 'OPEN' || chr.status == 'CLOSED',
    )
    const pending: Array<CallHomeRequests> = dataCHR.filter((chr) => chr.status == 'NEW')

    return dataCHR.length > 0 ? (
        <ChrTablePopulated openRequests={open} pendingRequests={pending} />
    ) : (
        <NoDataTable text="No call-home-requests found." />
    )
}
