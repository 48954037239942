import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const CardArea = styled.div`
    flex: 1;
    padding: ${spacing(2)};
    background-color: ${(props) => props.theme.chrList.dataArea.background};
    overflow-x: hidden;
    overflow-y: auto;
`
export const CHRCards = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: ${spacing(6)};
    min-width: 300px;
`
