import * as Styled from './filter-switch.styled'
import {ToggleLeft, ToggleRight} from 'react-feather'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {currentFilterStateSelector} from '../../../../store/state/chr-filter/selectors'
import {useDispatch} from 'react-redux'
import {setFilterState} from '../../../../store/state/chr-filter/action-creators'
import {useChr} from '../../contexts/use-chr'

export function FilterSwitch(): JSX.Element {
    const onlyUserCHR = useTypedSelector(currentFilterStateSelector)
    const dispatch = useDispatch()
    const {terminalExpanded} = useChr()

    function onClickHandler() {
        if (terminalExpanded) {
            alert('Please minimize terminal before filtering CHRs')
        } else {
            dispatch(setFilterState(!onlyUserCHR))
        }
    }

    return (
        <>
            <Styled.SwitchBox>
                <Styled.SwitchToggle>
                    <Styled.MiniPadding>All CHRs</Styled.MiniPadding>
                    <Styled.ToggleButton selectable>
                        {onlyUserCHR ? (
                            <ToggleRight
                                onClick={() => {
                                    onClickHandler()
                                }}
                                fill="#1f88e5"
                                color="#f7f7f7"
                                width={27}
                                height={27}
                            />
                        ) : (
                            <ToggleLeft
                                onClick={() => {
                                    onClickHandler()
                                }}
                                fill="#c9c9c9"
                                color="#f7f7f7"
                                width={27}
                                height={27}
                            />
                        )}
                    </Styled.ToggleButton>
                    <Styled.MiniPadding>Mine only</Styled.MiniPadding>
                </Styled.SwitchToggle>
            </Styled.SwitchBox>
        </>
    )
}
