import {Copy} from 'react-feather'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import * as Styled from '../_styled/button-cell.styled'
import {BREAK_POINT_BUTTON_REDUCED_TEXT} from '../_styled/button-cell.styled'

interface Props {
    locationCode: string | number
    portNumber: number
    isTerminalExpanded: boolean
}
export function CopyButtonCell({locationCode, portNumber, isTerminalExpanded}: Props): JSX.Element {
    const {width} = useDimensions()
    const text =
        width && width > BREAK_POINT_BUTTON_REDUCED_TEXT && !isTerminalExpanded
            ? 'Copy Cmd'
            : 'Copy'

    return (
        <Styled.Button
            width={width}
            id="copy-button-cell"
            onClick={() => {
                navigator.clipboard.writeText(`vessel-attach ${locationCode} ${portNumber}`)
            }}>
            <Copy height={14} width={14} />
            {text}
        </Styled.Button>
    )
}
