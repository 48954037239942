import {CallHomeRequests} from '../../../../values/call-home-request'
import {useRef} from 'react'
import {useChr} from '../../contexts/use-chr'
import {showCards} from '../../data-helpers'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {currentUserSelector} from '../../../../store/state/user/selectors'
import {currentFilterStateSelector} from '../../../../store/state/chr-filter/selectors'
import {OpenChrs} from '../open-chrs/open-chrs'
import {PendingToggle} from './pending-toggle'

interface Props {
    openRequests: CallHomeRequests[]
    pendingRequests: CallHomeRequests[]
}
export function ChrTablePopulated({openRequests, pendingRequests}: Props): JSX.Element {
    const clickOutside = useRef<HTMLDivElement>(null)
    const {selectedTabIndex, terminalExpanded} = useChr()
    const {width} = useDimensions()
    const breakpointMet = showCards(width)
    const currentUser = useTypedSelector(currentUserSelector)
    const onlyUserCHR = useTypedSelector(currentFilterStateSelector)
    const reducedOpen: Array<CallHomeRequests> = openRequests.filter(
        (chr) => chr.user.name == currentUser.name,
    )
    const reducedPending: Array<CallHomeRequests> = pendingRequests.filter(
        (chr) => chr.user.name == currentUser.name,
    )

    return (
        <>
            <PendingToggle
                pendingRequests={pendingRequests}
                reducedPending={reducedPending}
                breakpointMet={breakpointMet}
                terminalExpanded={terminalExpanded}
                selectedTabIndex={selectedTabIndex}
                clickOutside={clickOutside}
                onlyUserCHR={onlyUserCHR}
            />
            {terminalExpanded ? (
                ''
            ) : (
                <OpenChrs
                    openRequests={openRequests}
                    reducedOpen={reducedOpen}
                    breakpointMet={breakpointMet}
                    terminalExpanded={terminalExpanded}
                    selectedTabIndex={selectedTabIndex}
                    clickOutside={clickOutside}
                    onlyUserCHR={onlyUserCHR}
                />
            )}
        </>
    )
}
