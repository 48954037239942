import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {CHRStatus} from '../../../../../values/call-home-request'

interface TableDataRowProps {
    gridRow: number
    span?: number
    status?: CHRStatus
    active?: boolean
    isTerminalExpanded?: boolean
}

export const TableDataRow = styled.div<TableDataRowProps>`
    grid-row: ${(props) => `${props.gridRow} / span ${props.span ?? 1}`};
    grid-column-start: 1;
    grid-column-end: -1;
    color: ${(props) => props.theme.chrList.dataRow.text};
    background-color: ${(props) => props.theme.chrList.dataRow.background};
    min-height: 64px;
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    ${(props) => (props.active && props.isTerminalExpanded ? `border: 3px solid #cccccc;` : '')};
    border-left: thick solid
        ${(props) => {
            switch (props.status) {
                case 'NEW':
                    return props.theme.colors.newIndicator.flash
                case 'CLOSED':
                    return '#cccccc'
                case 'OPEN':
                    return '#79c943'
                default:
                    return props.theme.colors.newIndicator.flash
            }
        }};
`

interface TableDataCellProps {
    gridRow: number
    gridColumn: number
    extrabold?: boolean
    warning?: boolean
    noOccurrence?: boolean
    centered?: boolean
}

export const TableDataCell = styled.div<TableDataCellProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column: ${(props) => props.gridColumn};
    margin-left: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
    justify-content: flex-start;
    align-self: center;
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.normal};
`
