import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../../../store/state/locations/selectors'
import {ChangeEvent} from 'react'
import {populateLocationCodeAction} from '../../../../../store/state/create-call-home-request/action-creators'
import {
    environmentSelector,
    locationCodeSelector,
} from '../../../../../store/state/create-call-home-request/selectors'
import {useDispatch} from 'react-redux'
import {Select} from './select-location-entry.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'

export function SelectLocationEntry(): JSX.Element {
    const dispatch = useDispatch()
    const {width} = useDimensions()
    const locations = useTypedSelector(locationsSelector)
    function locationChangeHandler(e: ChangeEvent<HTMLSelectElement>) {
        dispatch(populateLocationCodeAction(e.target.value))
    }
    const newLocationCode = useTypedSelector(locationCodeSelector)
    const selectedEnvironment = useTypedSelector(environmentSelector)

    return (
        <Select
            onChange={locationChangeHandler}
            value={newLocationCode}
            id="location-code-entry"
            width={width}>
            <option key="default-option" value="">
                Select Location Code
            </option>
            {locations
                ?.filter((e) =>
                    selectedEnvironment === ''
                        ? e.environment
                        : e.environment === selectedEnvironment,
                )
                .map((value) => (
                    <option key={value.location} value={value.code}>
                        {value.code}
                    </option>
                ))}
        </Select>
    )
}
