import {ProcessingStatus} from '../../../values/call-home-request'
import {GuidType} from '../../../values/generic-type-defintions'
import AppState from '../../types/app-state'

export const hasAnyIdToDeleteSelector = (state: AppState): boolean =>
    state.deleteCallHomeRequest.idToDelete !== null
export const getIdToDeleteSelector = (state: AppState): GuidType | null =>
    state.deleteCallHomeRequest.idToDelete
export const isProcessingSelector = (state: AppState): boolean =>
    state.deleteCallHomeRequest.isProcessing === ProcessingStatus.PROCESSING
export const errorSelector = (state: AppState): string => state.deleteCallHomeRequest.error
