import {MouseEvent} from 'react'
import * as Styled from './delete-button.styled'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {useDispatch} from 'react-redux'
import {getIdToDeleteSelector} from '../../../../../store/state/delete-call-home-request/selectors'
import {deleteCallHomeRequest} from '../../../../../store/state/delete-call-home-request/action-creators'
import {callHomeRequestsSelector} from '../../../../../store/state/call-home-request/selectors'
import {chrToDelete} from '../helpers'
import {useChr} from '../../../../../pages/chr-listing/contexts/use-chr'

export function DeleteButton(): JSX.Element {
    const dataCHR = useTypedSelector(callHomeRequestsSelector)
    const idToDelete = useTypedSelector(getIdToDeleteSelector)
    const dispatch = useDispatch()
    const {toggleTerminalExpanded} = useChr()

    const callHomeRequest = chrToDelete(idToDelete, dataCHR)
    if (!callHomeRequest) {
        return <Styled.Error>Delete Button Error: no ID</Styled.Error>
    }
    function deleteChr(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        toggleTerminalExpanded(false)
        dispatch(deleteCallHomeRequest())
    }

    return <Styled.Button onClick={deleteChr}>Delete CHR</Styled.Button>
}
