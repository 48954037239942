import {CallHomeRequests} from '../../../values/call-home-request'

export interface CallHomeRequestsReduxState {
    isFetching: boolean
    callHomeRequests: CallHomeRequests[]
}

export const DEFAULT_CALL_HOME_REQUESTS_STATE: CallHomeRequestsReduxState = {
    isFetching: true,
    callHomeRequests: [],
}
