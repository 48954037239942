import ActionType from './action-type'
import {Action} from './actions'

import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import {DeleteCallHomeRequestReduxState, DEFAULT_DELETE_CALL_HOME_REQUEST_STATE} from './state'
import {ProcessingStatus} from '../../../values/call-home-request'

export default function deleteCallHomeRequestReducer(
    state: DeleteCallHomeRequestReduxState = DEFAULT_DELETE_CALL_HOME_REQUEST_STATE,
    action: Action | LoggingOutAction,
): DeleteCallHomeRequestReduxState {
    switch (action.type) {
        case ActionType.DISPLAY_DELETE_MODAL:
            return {
                ...state,
                idToDelete: action.payload,
            }
        case ActionType.CLOSE_DELETE_MODAL:
            return {
                ...state,
                idToDelete: null,
            }
        case ActionType.DELETE_CALL_HOME_REQUEST:
            return {
                ...state,
                isProcessing: ProcessingStatus.PROCESSING,
            }
        case ActionType.CALL_HOME_REQUEST_DELETED:
            return {
                ...state,
                idToDelete: null,
                isProcessing: ProcessingStatus.PROCESSED,
            }
        case ActionType.SET_ERROR:
            const isSameError = state.error === action.payload
            const stateIsNotProcessing = state.isProcessing === ProcessingStatus.NOT_PROCESSING
            if (stateIsNotProcessing && isSameError) {
                return state
            }
            return {
                ...state,
                error: isSameError ? state.error : action.payload,
                isProcessing: stateIsNotProcessing
                    ? state.isProcessing
                    : ProcessingStatus.NOT_PROCESSING,
            }

        case SessionActionType.LOGGING_OUT:
            return DEFAULT_DELETE_CALL_HOME_REQUEST_STATE
        /* istanbul ignore next */
        default:
            return state
    }
}
