import AppState from '../../../../store/types/app-state'

function compareRecord(a: string, b: string): number {
    return a.localeCompare(b)
}

export const sortedEnvironmentsSelector = (state: AppState): string[] =>
    [
        ...new Set(
            state.locations.locations.map((location) => {
                return location.environment
            }),
        ),
    ].sort(compareRecord)
