import {CyberOwlTheme} from './theme'
import {Colors} from './colours'

export const LightTheme: CyberOwlTheme = {
    colors: {
        text: {
            default: Colors.grey5,
            deemphasize: Colors.grey2,
            error: Colors.red4,
            warning: Colors.red3,
        },
        background: {
            selectedLocationPage: Colors.grey13,
            page: Colors.grey1,
            default: Colors.white,
            lowContrast: Colors.grey6,
        },
        boxShadow: {
            highContrast: '#00000034',
        },
        link: {
            primary: Colors.blue2,
        },
        border: {
            lowContrast: Colors.grey2,
            active: Colors.blue2,
        },
        newIndicator: {
            text: Colors.red3,
            flash: Colors.red4,
            background: Colors.red5,
        },
    },
    login: {
        errorBorder: Colors.red3,
        errorBackground: Colors.red3_21percent,
        errorContent: Colors.white,
    },
    loadingPage: {
        background: Colors.grey1,
    },
    navigationResponsive: {
        dataArea: {
            background: Colors.grey7,
            miniBackground: Colors.grey5,
            locationExpandedArea: Colors.grey4,
            textColor: Colors.grey1,
            miniTextColor: Colors.grey11,
        },
        active: {
            background: Colors.red3,
            underLine: Colors.orange2,
        },
    },
    defaultButton: {
        default: {
            borderColor: 'transparent',
            backgroundColor: 'default',
            textColor: Colors.blue2,
        },
        hover: {
            borderColor: 'transparent',
            backgroundColor: Colors.blue2,
            textColor: Colors.white,
        },
    },
    refreshButton: {
        default: {
            borderColor: 'transparent',
            backgroundColor: 'default',
            textColor: Colors.blue2,
        },
        hover: {
            borderColor: 'transparent',
            backgroundColor: Colors.blue2,
            textColor: Colors.white,
        },
        active: {
            borderColor: Colors.blue5,
            backgroundColor: Colors.blue2,
            textColor: Colors.white,
        },
        disabled: {
            borderColor: '#transparent',
            backgroundColor: 'default',
            textColor: Colors.white,
        },
    },
    dashboard: {
        noData: {
            text: Colors.grey2,
        },
    },
    chrList: {
        dataArea: {
            background: Colors.grey6,
            boxShadow: 'rgba(0, 0, 0, 0.3)',
        },
        dataRow: {
            text: Colors.grey5,
            background: Colors.white,
        },
        link: {
            primary: Colors.blue2,
            hoverBackground: Colors.blue6,
        },
    },
    font: {
        family: "'Open Sans', sans-serif",
        weight: {
            light: 300,
            normal: 400,
            semibold: 600,
            bold: 700,
            extrabold: 800,
        },
    },
    modalDialog: {
        background: Colors.white,
        text: Colors.grey5,
        headerText: Colors.white,
        headerBackground: Colors.grey7,
        overlay: 'rgba(0, 0, 0, 0.1)',
        divider: Colors.grey2,
        dropShadow: Colors.grey4,
        dataArea: {
            background: Colors.grey6,
        },
        dataRow: {
            text: Colors.grey5,
            background: Colors.white,
            border: '#000000ff',
        },
        tabs: {
            selected: {
                text: Colors.blue2,
                background: Colors.white,
            },
            notSelected: {
                text: Colors.grey5,
                background: Colors.grey6,
            },
        },
    },
}
