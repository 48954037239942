import styled from 'styled-components'
import {spacing} from '../../theme/spacing'

export const ButtonStyled = styled.button`
    border: none;
    background: none;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.defaultButton.default.textColor};
    background-color: ${(props) => props.theme.defaultButton.default.backgroundColor};
    text-decoration: none;
    border: 1px solid;
    border-radius: 6px;
    padding: ${spacing(1)};
    :disabled {
        opacity: 0.5;
    }
    &:hover {
        color: ${(props) => props.theme.defaultButton.hover.textColor};
        background-color: ${(props) => props.theme.defaultButton.hover.backgroundColor};
    }
`
