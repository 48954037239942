import styled from 'styled-components'
import {smallFont} from '../../../theme/font-styles'
import {spacing} from '../../../theme/spacing'

export const ErrorParagraph = styled.p`
    position: fixed;
    color: black;
    padding-top: ${spacing(20)};
    font-family: Open Sans, sans-serif;
    font-size: ${smallFont()} !important;
    fontweight: 300;
    zindex: 1;
`
