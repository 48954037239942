import ActionType from './action-type'
import {Action} from './actions'

import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'

import {LocationsReduxState, DEFAULT_LOCATIONS_STATE, Location} from './state'
import _ from 'lodash'

function compareLocationsArrayArray(currentState: Location[], newState: Location[]): boolean {
    return _.isEqual(currentState, newState)
}
function setLocations(
    currentState: LocationsReduxState,
    receivedData: Location[],
): LocationsReduxState {
    const newLocations = receivedData
    const isLocationsSameState = compareLocationsArrayArray(currentState.locations, receivedData)
    if (currentState.isFetching === false && isLocationsSameState) {
        return currentState
    }
    return {
        ...currentState,
        isFetching: false,
        locations: isLocationsSameState ? currentState.locations : newLocations,
    }
}

export function locationsReducer(
    state: LocationsReduxState = DEFAULT_LOCATIONS_STATE,
    action: Action | LoggingOutAction,
): LocationsReduxState {
    switch (action.type) {
        case ActionType.REQUEST_LOCATIONS:
            if (state.isFetching) {
                return state
            }
            return {
                ...state,
                isFetching: true,
            }
        case ActionType.SET_LOCATIONS:
            return setLocations(state, action.payload)
        case SessionActionType.LOGGING_OUT:
            return DEFAULT_LOCATIONS_STATE

        /* istanbul ignore next */
        default:
            return state
    }
}
