import authReducer from './state/auth/reducer'
import callHomeRequestReducer from './state/call-home-request/reducer'
import configReducer from './state/config/reducer'
import {locationsReducer} from './state/locations/reducer'
import deleteCallHomeRequestReducer from './state/delete-call-home-request/reducer'
import sessionDataReducer from './state/session-data/reducer'
import createCallHomeRequestReducer from './state/create-call-home-request/reducer'
import userReducer from './state/user/reducer'
import filterReducer from './state/chr-filter/reducer'

export const staticReducers = {
    auth: authReducer,
    config: configReducer,
    sessionData: sessionDataReducer,
    callHomeRequests: callHomeRequestReducer,
    locations: locationsReducer,
    deleteCallHomeRequest: deleteCallHomeRequestReducer,
    createCallHomeRequest: createCallHomeRequestReducer,
    user: userReducer,
    CHRFilter: filterReducer,
}

export default staticReducers
