import {spacing} from '../../theme/spacing'
import moment, {Moment} from 'moment'

export function showCards(width: number | undefined): boolean | null {
    if (!width) {
        return null
    }
    return width < 1300
}

export function hideHeaders(width: number | undefined): boolean | null {
    if (!width) {
        return null
    }
    return width < 1300
}

export function getGridTemplateColumns(): string {
    return `${spacing(2)} auto 180px 180px 180px 180px 320px ${spacing(2)}`
}

export function getReducedGridTemplateColumns(): string {
    return `${spacing(2)} 150px auto auto auto auto 230px ${spacing(2)}`
}

export const BREAK_POINT_CARD_REDUCED_TEXT = 620

export function getFormattedParam(param: string | number): string | number {
    return param ? param : 'Unknown'
}

const DEFAULT_DATE_FORMAT = 'DD MMM YYYY (HH:mm)'
export const formatDate = (date: string | Moment): string => {
    return date && moment(date).isValid() ? moment(date).format(DEFAULT_DATE_FORMAT) : 'N/A'
}

export function getFormattedUrl(code: string): string {
    return `/terminal/${code.toLocaleLowerCase()}`
}
