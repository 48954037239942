import styled from 'styled-components'

interface ValueProps {
    gridArea: string
}

export const Value = styled.div<ValueProps>`
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    grid-area: ${(props) => props.gridArea};
`
