import {CancelButton} from '../cancel-button/cancel-button'
import {CloseButton} from '../close-button/close-button'
import * as Styled from './chr-actions.styled'
export function ChrActions(): JSX.Element {
    return (
        <>
            <Styled.Title>Are you sure you want to minimise the terminal?</Styled.Title>
            <Styled.ButtonsArea>
                <CancelButton />
                <CloseButton />
            </Styled.ButtonsArea>
        </>
    )
}
