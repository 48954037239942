import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

interface TabContentProps {
    active: boolean
}

export const TabContent = styled.div<TabContentProps>`
    ${(props) => (props.active ? `margin: 0 ${spacing(4)}; flex:1; ` : 'display:none;')}
`

export const InfoLayout = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: ${spacing(4)};
    background-color: ${(props) => props.theme.chrList.dataRow.background};
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    border-bottom: none;
`

export const TabPosition = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
`
