import {MouseEvent} from 'react'
import {useChr} from '../../../../../pages/chr-listing/contexts/use-chr'
import * as Styled from './cance-button.styled'

export function CancelButton(): JSX.Element {
    const {setShowCloseTerminalModal} = useChr()
    function cancelChanges(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        setShowCloseTerminalModal(false)
    }

    return (
        <Styled.CancelButton id="cancel-changes-button" onClick={cancelChanges}>
            Cancel
        </Styled.CancelButton>
    )
}
