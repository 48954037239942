import {DataLoading} from '../../../../components/data-loading/data-loading'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {isFetchingCallHomeRequestsSelector} from '../../../../store/state/call-home-request/selectors'
import {ChrTableLoaded} from './chr-list-table-loaded'
export function ChrListTableContent(): JSX.Element {
    const loading = useTypedSelector(isFetchingCallHomeRequestsSelector)

    if (loading) {
        return <DataLoading />
    }
    return <ChrTableLoaded />
}
