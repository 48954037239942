import {CardArea} from '../_styled/chr-cards-populated.styled'
import * as Styles from './no-data.styled'

interface NoDataCardProps {
    text: string
}
export function NoDataCard({text}: NoDataCardProps): JSX.Element {
    return (
        <CardArea>
            <Styles.NoDataCardStyle>
                <Styles.NoDataMessage>{text}</Styles.NoDataMessage>
            </Styles.NoDataCardStyle>
        </CardArea>
    )
}
