import * as Styled from './styles/secondary-button.styled'
interface GetHelpButtonProps {
    smallText: boolean
}

export function GetHelpButton({smallText}: GetHelpButtonProps): JSX.Element {
    return (
        <Styled.SecondaryButton
            id="get-help-link"
            smallText={smallText}
            rel="noreferrer"
            target="_blank"
            href="mailto:support@cyberowl.io?subject=Cannot log into Beacon">
            Contact CyberOwl Admin For Help
        </Styled.SecondaryButton>
    )
}
