import {LoadingSpinner} from '../../../../loading/loading'
import * as Styled from '../deletion-in-progress/deletion-in-progress.styled'
export function DeletionInProgress(): JSX.Element {
    return (
        <>
            <LoadingSpinner size={60} />
            <Styled.Text>Deleting...</Styled.Text>
        </>
    )
}
