import {CreationStatus, ProcessingStatus} from '../../../values/call-home-request'
import AppState from '../../types/app-state'

export const isChrCreatingSelector = (state: AppState): boolean =>
    state.createCallHomeRequest.creationChrStatus === CreationStatus.ENTER_DETAILS
export const isProcessingSelector = (state: AppState): boolean =>
    state.createCallHomeRequest.isProcessing === ProcessingStatus.PROCESSING
export const errorSelector = (state: AppState): string => state.createCallHomeRequest.error
export const locationCodeSelector = (state: AppState): string =>
    state.createCallHomeRequest.locationCode
export const environmentSelector = (state: AppState): string =>
    state.createCallHomeRequest.environment
