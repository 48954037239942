import {CallHomeRequests} from '../../../../values/call-home-request'
import {TableDataGridStyle, TableWrapper} from '../table/_styled/chr-list-table.styled'
import {NoDataCard} from '../cards/no-data-card/no-data-card'
import {PendingTableRow} from '../table/pending-table-row'
import {FlexWrapper} from './pending-chrs.styled'

interface Props {
    pendingRequests: CallHomeRequests[]
    reducedPending: CallHomeRequests[]
    breakpointMet: boolean | null
    terminalExpanded: boolean
    selectedTabIndex: number
    clickOutside: React.RefObject<HTMLDivElement>
    onlyUserCHR: boolean
}
export function PendingChrs({
    pendingRequests,
    reducedPending,
    breakpointMet,
    terminalExpanded,
    selectedTabIndex,
    clickOutside,
    onlyUserCHR,
}: Props): JSX.Element {
    return (
        <>
            {reducedPending.length == 0 && onlyUserCHR ? (
                <NoDataCard text={'No pending call-home-requests found.'} />
            ) : (
                <TableWrapper breakpointMet={breakpointMet}>
                    <FlexWrapper terminalExpanded={terminalExpanded} ref={clickOutside}>
                        <TableDataGridStyle
                            showTerminal={terminalExpanded}
                            breakpointMet={breakpointMet}>
                            {onlyUserCHR
                                ? reducedPending.map((callHomeRequest, index) => (
                                      <PendingTableRow
                                          key={callHomeRequest.identity}
                                          callHomeRequest={callHomeRequest}
                                          index={index}
                                          active={selectedTabIndex === index}
                                      />
                                  ))
                                : pendingRequests.map((callHomeRequest, index) => (
                                      <PendingTableRow
                                          key={callHomeRequest.identity}
                                          callHomeRequest={callHomeRequest}
                                          index={index}
                                          active={selectedTabIndex === index}
                                      />
                                  ))}
                        </TableDataGridStyle>
                    </FlexWrapper>
                </TableWrapper>
            )}
        </>
    )
}
