import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {ContentContainerTop} from '../../templates/fixed-page/content-container-top.styled'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import {DisplayArea} from './swagger-link.styled'
import {LinkArea} from './components/link-area/link-area'
import {TokenArea} from './components/token-area/token-area'

export function SwaggerLink(): JSX.Element {
    const {width} = useDimensions()
    return (
        <FixedPageTemplate>
            <ContentContainerTop width={width}>
                <DisplayArea width={width}>
                    <TokenArea />
                    <LinkArea />
                </DisplayArea>
            </ContentContainerTop>
        </FixedPageTemplate>
    )
}
