import {Location} from '../../../../../store/state/locations/state'
import * as Styled from './vessel-name-dropdown.styled'
import {populateLocationCodeAction} from '../../../../../store/state/create-call-home-request/action-creators'
import {useDispatch} from 'react-redux'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'

interface VesselNameDropdownProps {
    locations: Location[]
    setSearchValue: (value: string) => void
    setIsExpanded: (value: boolean) => void
    isEmpty: boolean
}

export function VesselNameDropdown({
    locations,
    setSearchValue,
    setIsExpanded,
    isEmpty,
}: VesselNameDropdownProps): JSX.Element {
    const dispatch = useDispatch()
    const {width} = useDimensions()

    function onClickHandler(e: string) {
        setSearchValue(e)
        dispatch(
            populateLocationCodeAction(
                locations.find((location) => location.description.toLowerCase() === e.toLowerCase())
                    ?.code || '',
            ),
        )
        setIsExpanded(false)
    }

    return (
        <>
            {(!isEmpty && (
                <Styled.Dropdown width={width}>
                    {locations.map((location) => (
                        <Styled.Ul
                            key={location.code}
                            onClick={() => onClickHandler(location.description)}>
                            <Styled.Li width={width}>{location.description}</Styled.Li>
                        </Styled.Ul>
                    ))}
                </Styled.Dropdown>
            )) || (
                <Styled.Dropdown width={width}>
                    <Styled.Ul>
                        <Styled.Li width={width} style={{textAlign: 'center'}}>
                            - No Vessels Found -
                        </Styled.Li>
                    </Styled.Ul>
                </Styled.Dropdown>
            )}
        </>
    )
}
