import {TableDataCell, TableDataRow} from '../_styled/table-row.styled'
import {NoDataMessage, NoDataMessageGrid} from './no-data.styled'

interface NoDataTableProps {
    text: string
}
export function NoDataTable({text}: NoDataTableProps): JSX.Element {
    return (
        <NoDataMessageGrid>
            <TableDataRow gridRow={1} />
            <TableDataCell gridRow={1} gridColumn={5} centered>
                <NoDataMessage>{text}</NoDataMessage>
            </TableDataCell>
        </NoDataMessageGrid>
    )
}
