import {ProcessingStatus} from '../../../values/call-home-request'
import {GuidType} from '../../../values/generic-type-defintions'
export interface DeleteCallHomeRequestReduxState {
    idToDelete: GuidType | null
    error: string
    isProcessing: ProcessingStatus
}

export const DEFAULT_DELETE_CALL_HOME_REQUEST_STATE: DeleteCallHomeRequestReduxState = {
    idToDelete: null,
    error: '',
    isProcessing: ProcessingStatus.NOT_PROCESSING,
}
