import * as Styled from './close-terminal-form.styled'
import {ChrActions} from './chr-actions/chr-actions'

export function CloseTerminal(): JSX.Element {
    return (
        <Styled.Section onClick={(e) => e.stopPropagation()}>
            <Styled.Header>
                <Styled.Title>Minimise Terminal</Styled.Title>
            </Styled.Header>

            <Styled.Actions>
                <ChrActions />
            </Styled.Actions>
        </Styled.Section>
    )
}
