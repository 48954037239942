import {useDispatch} from 'react-redux'
import {MouseEvent} from 'react'
import * as Styled from './cance-button.styled'
import {closeCreateModal} from '../../../../../../store/state/create-call-home-request/action-creators'
export function CancelButton(): JSX.Element {
    const dispatch = useDispatch()
    function cancelChanges(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        dispatch(closeCreateModal())
    }
    return (
        <Styled.CancelButton id="cancel-changes-button" onClick={cancelChanges}>
            Cancel
        </Styled.CancelButton>
    )
}
