import ActionType from './action-type'
import {Action} from './actions'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'

import {
    ConfigFetchingStatus,
    ConfigReduxState,
    DEFAULT_CONFIG_STATE,
    DEFAULT_OAUTH_CLIENT_IDS,
    OAuthClientIdsType,
} from './state'
import {BeaconConfig} from '../../../values/beacon-config'

function requestConfig(currentState: ConfigReduxState): ConfigReduxState {
    if (
        currentState.auth === '' &&
        JSON.stringify(currentState.oauthClientIds) === JSON.stringify(DEFAULT_OAUTH_CLIENT_IDS) &&
        (currentState.fetchingStatus === ConfigFetchingStatus.REREQUESTING ||
            currentState.fetchingStatus === ConfigFetchingStatus.REQUESTING)
    ) {
        return currentState
    }
    return {
        ...currentState,
        auth: '',
        fetchingStatus:
            currentState.fetchingStatus === ConfigFetchingStatus.NEEDS_RESETTING
                ? ConfigFetchingStatus.REREQUESTING
                : ConfigFetchingStatus.REQUESTING,
        oauthClientIds: DEFAULT_OAUTH_CLIENT_IDS,
    }
}

function receiveConfig(currentState: ConfigReduxState, newState: BeaconConfig): ConfigReduxState {
    if (
        currentState.auth === newState.auth &&
        JSON.stringify(currentState.oauthClientIds) === JSON.stringify(newState.oauthClientIds) &&
        currentState.fetchingStatus === ConfigFetchingStatus.INITIALISED
    ) {
        return currentState
    }

    return {
        ...currentState,
        auth: newState.auth as string,
        fetchingStatus: ConfigFetchingStatus.INITIALISED,
        oauthClientIds: newState.oauthClientIds as OAuthClientIdsType,
    }
}
function logout(currentState: ConfigReduxState): ConfigReduxState {
    if (
        currentState.auth === '' &&
        JSON.stringify(currentState.oauthClientIds) ===
            JSON.stringify(currentState.oauthClientIds) &&
        currentState.fetchingStatus === ConfigFetchingStatus.NEEDS_RESETTING
    ) {
        return currentState
    }
    return {
        ...DEFAULT_CONFIG_STATE,
        fetchingStatus: ConfigFetchingStatus.NEEDS_RESETTING,
    }
}

export default function configReducer(
    state: ConfigReduxState = DEFAULT_CONFIG_STATE,
    action: Action | LoggingOutAction,
): ConfigReduxState {
    switch (action.type) {
        case ActionType.REQUEST_CONFIG:
            return requestConfig(state)
        case ActionType.RECEIVE_CONFIG:
            return receiveConfig(state, action.payload)
        case SessionActionType.LOGGING_OUT:
            return logout(state)
        /* istanbul ignore next */
        default:
            return state
    }
}
