import PersistableAppState from './persistable-app-state'
import {ConfigReduxState} from '../state/config/state'
import {SessionDataReduxState} from '../state/session-data/state'
import {CallHomeRequestsReduxState} from '../state/call-home-request/state'
import {LocationsReduxState} from '../state/locations/state'
import {DeleteCallHomeRequestReduxState} from '../state/delete-call-home-request/state'
import {CreateCallHomeRequestReduxState} from '../state/create-call-home-request/state'
import {CHRFilterReduxState} from '../state/chr-filter/state'

interface AppState extends PersistableAppState {
    sessionData: SessionDataReduxState
    config: ConfigReduxState
    callHomeRequests: CallHomeRequestsReduxState
    locations: LocationsReduxState
    deleteCallHomeRequest: DeleteCallHomeRequestReduxState
    createCallHomeRequest: CreateCallHomeRequestReduxState
    CHRFilter: CHRFilterReduxState
}

export function toPersistableAppState(state: AppState): PersistableAppState {
    return {
        auth: state.auth,
        user: state.user,
    }
}

export default AppState
