import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import {REST} from '../../..'
import {Api} from '../../../api/api'
import {GuidType} from '../../../values/generic-type-defintions'

const CALL_HOME_REQUESTS_REST_URL = 'api/v1/chrs'

export function displayDeleteModal(requestIdentity: GuidType): Actions.ConfirmDeletionAction {
    return {
        type: ActionType.DISPLAY_DELETE_MODAL,
        payload: requestIdentity,
    }
}
export function closeDeleteModal(): Actions.CancelDeletionAction {
    return {
        type: ActionType.CLOSE_DELETE_MODAL,
    }
}
function setIsProcessingAction(): Actions.DeleteCallHomeRequestAction {
    return {
        type: ActionType.DELETE_CALL_HOME_REQUEST,
    }
}
function callHomeRequestDeletedAction(identity: GuidType): Actions.CallHomeRequestDeletedAction {
    return {
        type: ActionType.CALL_HOME_REQUEST_DELETED,
        payload: identity,
    }
}
function setError(error: string): Actions.SetError {
    return {type: ActionType.SET_ERROR, payload: error}
}

export function deleteCallHomeRequest(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch, getState) => {
        dispatch(setIsProcessingAction())
        const identity = getState().deleteCallHomeRequest.idToDelete
        REST.delete(`${CALL_HOME_REQUESTS_REST_URL}/${identity}`)
            .then((response) => dispatch(callHomeRequestDeletedAction(response.data.identity)))
            .catch((err) => dispatch(setError(err)))
    }
}
