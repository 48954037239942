import {Overlay} from '../shared/overlay'
import {CloseTerminal} from './components/close-terminal-form'

export function CloseTerminalModal(): JSX.Element {
    return (
        <Overlay
            onClickFn={(e) => {
                e && e.preventDefault()
            }}
            z={900}>
            <CloseTerminal />
        </Overlay>
    )
}
