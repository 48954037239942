import styled from 'styled-components'
import {mediumSmallFont, smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

function getGridTemplate(): string {
    return '"STATUS WHO WHEN"'
}

function getColumnTemplate(): string {
    return 'repeat(2,1fr);'
}
export const Card = styled.div`
    color: ${(props) => props.theme.chrList.dataRow.text};
    margin-bottom: ${spacing(1)};
    ${smallFont()};
`
interface CardAreaProps {
    width: number | undefined
}
export const CardAreaContent = styled.div<CardAreaProps>`
    display: grid;
    grid-template-columns: ${getColumnTemplate()};
    grid-template-areas: ${getGridTemplate()};
    gap: ${spacing(2)};
    padding: ${spacing(2)};
`
export const CardAreaTitle = styled.div<CardAreaProps>`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'LOCATION EMPTY PORT';
    gap: ${spacing(2)};
    padding: ${spacing(2)};
    border-bottom: ${(props) => `1px solid ${props.theme.colors.border.lowContrast}`};
`
interface CardTitleProps {
    gridArea: string
}
export const CardTitleData = styled.h2<CardTitleProps>`
    ${mediumSmallFont}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
    margin: 0;
    grid-area: ${(props) => props.gridArea};
`
