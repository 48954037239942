import * as Styled from './delete-chr-form.styled'
import {ChrDetails} from './chr-details/chr-details'

import {ChrActions} from './chr-actions/chr-actions'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {callHomeRequestsSelector} from '../../../../store/state/call-home-request/selectors'
import {
    getIdToDeleteSelector,
    isProcessingSelector,
} from '../../../../store/state/delete-call-home-request/selectors'
import {chrToDelete} from './helpers'
import {ErrorText} from './error-text/error-text'
import {DeletionInProgress} from './deletion-in-progress/deletion-in-progress'

export function DeleteCallHomeRequests(): JSX.Element {
    const dataCHR = useTypedSelector(callHomeRequestsSelector)
    const idToDelete = useTypedSelector(getIdToDeleteSelector)
    const isLoading = useTypedSelector(isProcessingSelector)

    const callHomeRequest = chrToDelete(idToDelete, dataCHR)
    return (
        <Styled.Section onClick={(e) => e.stopPropagation()}>
            <Styled.Header>
                <Styled.Title>Delete Call Home Requests</Styled.Title>
            </Styled.Header>
            <Styled.ChrDetailsContent>
                {callHomeRequest ? <ChrDetails callHomeRequest={callHomeRequest} /> : <ErrorText />}
            </Styled.ChrDetailsContent>
            <Styled.Actions>{isLoading ? <DeletionInProgress /> : <ChrActions />}</Styled.Actions>
        </Styled.Section>
    )
}
