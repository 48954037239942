import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {getGridTemplateColumns, getReducedGridTemplateColumns} from '../../../data-helpers'

interface TableDataGridStyleProps {
    showTerminal: boolean | undefined
    breakpointMet: boolean | null
}

interface TableWrapperProps {
    breakpointMet: boolean | null
}

export const TableDataGridStyle = styled.div<TableDataGridStyleProps>`
    align-content: flex-start;
    display: grid;
    grid-template-columns: ${(props) =>
        props.showTerminal || props.breakpointMet
            ? getReducedGridTemplateColumns()
            : getGridTemplateColumns()};
    background-color: ${(props) => props.theme.chrList.dataArea.background};
    gap: ${spacing(2)};
    padding: 0 ${spacing(4)} ${spacing(2)} ${spacing(4)};
    height: 100%;
    max-height: ${(props) => (props.breakpointMet && props.showTerminal ? '30vh' : '100%')};
    overflow-x: scroll;
    overflow-y: auto;
`

export const TableWrapper = styled.div<TableWrapperProps>`
    display: flex;
    flex-direction: ${(props) => (props.breakpointMet ? 'column' : 'row')};
    align-items: ${(props) => (!props.breakpointMet ? 'flex-start' : '')};
    gap: ${spacing(3)};
`
