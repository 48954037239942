/* eslint-disable @typescript-eslint/no-explicit-any */
import {AnyAction, createStore, Store, applyMiddleware} from 'redux'
import {default as thunkMiddleware, ThunkMiddleware} from 'redux-thunk'
import composeEnhancers from './compose-enhancers'
import {loadState, saveState} from './local-storage'
import AppState, {toPersistableAppState} from './types/app-state'
import PersistableAppState from './types/persistable-app-state'
import {INITIAL_STORE} from './types/initial-store'
import rootReducer from './root-reducer'
import {Api} from '../api/api'

export let api: Api

export default function configureStore(): Store<AppState> {
    api = new Api()

    // If it's a jest test don't try to use local storage
    const persistedStore: PersistableAppState | undefined =
        process.env.JEST_WORKER_ID === undefined ? loadState() : undefined

    const preloadedState = {...INITIAL_STORE, ...persistedStore}

    const enhancer = composeEnhancers(
        applyMiddleware(
            thunkMiddleware.withExtraArgument(api) as unknown as ThunkMiddleware<AppState>,
        ),
    )

    // eslint-disable-next-line @typescript-eslint/ban-types
    const store = createStore<any, AnyAction, any, {}>(rootReducer(), preloadedState, enhancer)

    // configure persistable state
    store.subscribe(() => saveState(toPersistableAppState(store.getState())))
    api.init(store)
    return store
}
