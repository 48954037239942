import ActionType from './action-type'
import * as Actions from './actions' // import { CustomerPortalUIConfig } from '../../values/CustomerPortalUIConfig';
import AppState from '../../types/app-state'
import {Api} from '../../../api/api'
import {ThunkAction} from 'redux-thunk'
import {BeaconConfig} from '../../../values/beacon-config'

export function requestConfig(): Actions.RequestConfigAction {
    return {
        type: ActionType.REQUEST_CONFIG,
    }
}

export function configRetrieved(config: BeaconConfig): Actions.ConfigRetrievedAction {
    return {
        type: ActionType.RECEIVE_CONFIG,
        payload: config,
    }
}

export function configError(reason: string): Actions.ConfigErrorAction {
    return {
        type: ActionType.CONFIG_ERROR,
        payload: reason,
    }
}

export function fetchConfig(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch, _, api: Api): void => {
        dispatch(requestConfig())

        api.fetchNotAuth(
            '/api/v1/config',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (config: any) => {
                dispatch(configRetrieved(config as BeaconConfig))
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (reason: any) => {
                dispatch(configError(reason))
            },
        )
    }
}
