import ActionType from './action-type'
import {Action} from './actions'

import {LoggingOutAction} from '../session-data/actions'
import DeleteActionType from '../delete-call-home-request/action-type'
import {Action as DeleteAction} from '../delete-call-home-request/actions'
import {Action as CreateAction} from '../create-call-home-request/actions'
import CreateActionType from '../create-call-home-request/action-type'

import SessionActionType from '../session-data/action-type'
import {CallHomeRequestsReduxState, DEFAULT_CALL_HOME_REQUESTS_STATE} from './state'
import _ from 'lodash'

import {CallHomeRequests} from '../../../values/call-home-request'
import {GuidType} from '../../../values/generic-type-defintions'

export default function callHomeRequestReducer(
    state: CallHomeRequestsReduxState = DEFAULT_CALL_HOME_REQUESTS_STATE,
    action: Action | LoggingOutAction | DeleteAction | CreateAction,
): CallHomeRequestsReduxState {
    switch (action.type) {
        case ActionType.REQUEST_CALL_HOME_REQUESTS:
            return {
                ...state,
                isFetching: true,
            }
        case ActionType.RECEIVE_CALL_HOME_REQUESTS:
            const isCallHomeRequestSameState = compareCallHomeRequestArray(
                state.callHomeRequests,
                action.payload,
            )

            if (isCallHomeRequestSameState && state.isFetching === false) {
                return state
            }
            return {
                ...state,
                callHomeRequests: isCallHomeRequestSameState
                    ? state.callHomeRequests
                    : action.payload,
                isFetching: false,
            }
        case DeleteActionType.CALL_HOME_REQUEST_DELETED:
            return {
                ...state,
                callHomeRequests: removeDeletedChr(action.payload, state.callHomeRequests),
            }
        case CreateActionType.CALL_HOME_REQUEST_CREATED:
            return {
                ...state,
                callHomeRequests: addNewChr(action.payload, state.callHomeRequests),
            }
        case SessionActionType.LOGGING_OUT:
            return DEFAULT_CALL_HOME_REQUESTS_STATE
        /* istanbul ignore next */
        default:
            return state
    }
}
function compareCallHomeRequestArray(
    currentState: CallHomeRequests[],
    newState: CallHomeRequests[],
): boolean {
    return _.isEqual(currentState, newState)
}
function removeDeletedChr(
    payload: GuidType | null,
    chrList: CallHomeRequests[],
): CallHomeRequests[] {
    if (!payload) {
        return chrList
    }
    const chrToBeRemoved = chrList?.find((item) => item.identity === payload)
    if (!chrToBeRemoved) {
        return chrList
    }
    const shallowCopyChrList = [...chrList]
    shallowCopyChrList.splice(chrList.indexOf(chrToBeRemoved), 1)
    return shallowCopyChrList
}
function addNewChr(payload: CallHomeRequests, chrList: CallHomeRequests[]): CallHomeRequests[] {
    return [...chrList, payload]
}
