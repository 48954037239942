import {CallHomeRequests} from '../../../../values/call-home-request'
import {useChr} from '../../contexts/use-chr'
import {formatDate, getFormattedParam} from '../../data-helpers'
import {ActionButtons} from '../shared/button-cell/action-buttons'
import {TableDataCell, TableDataRow} from './_styled/table-row.styled'
import {showCards} from '../../data-helpers'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'

interface TableRowProps {
    callHomeRequest: CallHomeRequests
    index: number
    active: boolean
}

export function TableRow({callHomeRequest, index, active}: TableRowProps): JSX.Element {
    const gridRow = index + 1
    const {terminalExpanded} = useChr()
    const {width} = useDimensions()
    const breakpointMet = showCards(width)

    return (
        <>
            <TableDataRow
                gridRow={gridRow}
                status={callHomeRequest.status}
                id={`data-row_${callHomeRequest.identity}`}
                active={active}
                isTerminalExpanded={terminalExpanded}
            />

            <TableDataCell
                gridRow={gridRow}
                gridColumn={2}
                id={`chr-list-location-code_${callHomeRequest.identity}`}>
                {getFormattedParam(callHomeRequest.locationCode)}
            </TableDataCell>
            {!terminalExpanded && !breakpointMet && (
                <>
                    <TableDataCell
                        gridRow={gridRow}
                        gridColumn={3}
                        id={`chr-list-port-number_${callHomeRequest.identity}`}>
                        {callHomeRequest.portNumber}
                    </TableDataCell>
                    <TableDataCell
                        gridRow={gridRow}
                        gridColumn={4}
                        id={`chr-list-status_${callHomeRequest.identity}`}>
                        {getFormattedParam(callHomeRequest.status)}
                    </TableDataCell>
                    <TableDataCell
                        gridRow={gridRow}
                        gridColumn={5}
                        id={`chr-list-user-name_${callHomeRequest.identity}`}>
                        {getFormattedParam(callHomeRequest.user.name)}
                    </TableDataCell>
                    <TableDataCell
                        gridRow={gridRow}
                        gridColumn={6}
                        id={`chr-list-updated-at_${callHomeRequest.identity}`}>
                        {formatDate(callHomeRequest.updatedAt)}
                    </TableDataCell>
                </>
            )}

            <TableDataCell
                gridRow={gridRow}
                gridColumn={7}
                id={`chr-list-actions_${callHomeRequest.identity}`}>
                <ActionButtons
                    locationCode={getFormattedParam(callHomeRequest.locationCode)}
                    portNumber={callHomeRequest.portNumber}
                    identity={callHomeRequest.identity}
                    index={index}
                />
            </TableDataCell>
        </>
    )
}
