import {FilePlus as CreateChrIcon} from 'react-feather'
import {useDispatch} from 'react-redux'
import {displayCreateModal} from '../../../../../store/state/create-call-home-request/action-creators'
import * as Styled from './create-chr-button.styled'

export function CreateChrButton(): JSX.Element {
    const dispatch = useDispatch()
    return (
        <>
            <Styled.Button
                id="refresh-button"
                onClick={() => {
                    dispatch(displayCreateModal())
                }}>
                <CreateChrIcon height={15} width={15} />
                Create CHR
            </Styled.Button>
        </>
    )
}
