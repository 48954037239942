import * as Styled from './_styled/terminal-styled'
import {ErrorMessage} from '../../components/err-message/err-message'
import {TerminalIFrame} from '../../components/terminal/terminal-iframe'
import {useEffect, useState} from 'react'

interface TerminalProps {
    terminalHeight: string
}

export function Terminal({terminalHeight}: TerminalProps): JSX.Element {
    const [gottyUrl, setGottyUrl] = useState<string | undefined>('')

    useEffect(() => {
        async function fetchConfig() {
            try {
                const result = await fetch(
                    `${process.env.PUBLIC_URL}/config/${
                        process.env.REACT_APP_ALT_BACKEND || 'beacon-ui'
                    }.json`,
                )
                const response = await result.json()
                setGottyUrl(response.gottyUrl)
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
            }
        }
        fetchConfig()
    }, [])

    return (
        <Styled.TerminalArea>
            <ErrorMessage />
            <TerminalIFrame terminalHeight={terminalHeight} gottyUrl={gottyUrl} />
        </Styled.TerminalArea>
    )
}
