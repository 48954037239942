import {LoadingSpinner} from '../../../../loading/loading'
import * as Styled from './creation-in-progress.styled'
export function CreationInProgress(): JSX.Element {
    return (
        <>
            <LoadingSpinner size={60} />
            <Styled.Text>Creating...</Styled.Text>
        </>
    )
}
