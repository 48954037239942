import AppState from '../../types/app-state'
// import {Role} from '../../../values/Role'
import {User, UserReduxState} from './state'
import LoadingState from '../../../values/loading-state-enum'

export const currentUserEmailSelector = (state: AppState): string => state.user.currentUserEmail

export const currentUserStateSelector = (state: AppState): UserReduxState => state.user

export const currentUserSelector = (state: AppState): User => state.user.user

// export const rolesSelector = (state: AppState): Role[] =>
//     state.user ? state.user.user.role : DEFAULT_USER_STATE.user.role

export const isFetchingRolesSelector = (state: AppState): LoadingState => state.user.isFetching
