import * as Styles from './_styles/logout.styled'
import {LogOut} from 'react-feather'
import {loggingOut} from '../../../store/state/session-data/action-creators'
import {useDispatch} from 'react-redux'

export function Logout(): JSX.Element {
    const dispatch = useDispatch()
    return (
        <Styles.Button
            id="logout-button"
            onClick={() => {
                dispatch(loggingOut())
            }}>
            <LogOut />
        </Styles.Button>
    )
}
