import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const NoDataMessageGrid = styled.div`
    display: grid;
    grid-template-columns: ${spacing(2)} 7px 1px 1px auto 1px 7px ${spacing(2)};
    grid-template-rows: max-content;
    padding: 0 ${spacing(2)} ${spacing(2)} ${spacing(2)};
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    background-color: ${(props) => props.theme.chrList.dataArea.background};
`
export const NoDataMessage = styled.div`
    margin: ${spacing(4)} 0;
    text-align: center;
    ${smallFont()}
`
