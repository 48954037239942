import {LogOut} from 'react-feather'
import {LinksWrapper, LogoutLink} from './_styled/hamburger-menu.styled'
import {NavBarContent} from './nav-bar-content'
import {loggingOut} from '../../../store/state/session-data/action-creators'
import {useDispatch} from 'react-redux'

export function HamburgerMenu(): JSX.Element {
    const dispatch = useDispatch()

    return (
        <LinksWrapper>
            <NavBarContent />
            <LogoutLink
                id="logout-button"
                onClick={() => {
                    dispatch(loggingOut())
                }}>
                <LogOut height={16} width={16} />
                Logout
            </LogoutLink>
        </LinksWrapper>
    )
}
