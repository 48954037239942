import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {ButtonWrapper} from '../_styled/button-cell.styled'
import {CopyButtonCell} from './copy-button-cell'
import {DeleteButtonCell} from './delete-button-cell'
import {useChr} from '../../../contexts/use-chr'

interface ActionButtonsProps {
    locationCode: string | number
    portNumber: number
    identity: GuidType
}

export function PendingActionButtons({
    locationCode,
    portNumber,
    identity,
}: ActionButtonsProps): JSX.Element {
    const {width} = useDimensions()
    const {terminalExpanded} = useChr()

    return (
        <ButtonWrapper width={width}>
            <CopyButtonCell
                locationCode={locationCode}
                portNumber={portNumber}
                isTerminalExpanded={terminalExpanded}
            />
            <DeleteButtonCell identity={identity} isTerminalExpanded={terminalExpanded} />
        </ButtonWrapper>
    )
}
