import {GuidType} from './generic-type-defintions'

export interface CallHomeRequests {
    identity: GuidType
    portNumber: number
    locationCode: string
    user: UserData
    status: CHRStatus
    createdAt: string
    updatedAt: string
}
export interface UserData {
    name: string
    email: string
}
export type CHRStatus = 'OPEN' | 'CLOSED' | 'NEW'
export enum ProcessingStatus {
    NOT_PROCESSING = 'NOT_PROCESSING',
    PROCESSING = 'PROCESSING',
    PROCESSED = 'PROCESSED',
}
export enum CreationStatus {
    NOT_CREATING = 'NOT_CREATING',
    ENTER_DETAILS = 'ENTER_DETAILS',
    CONFIRM = 'CONFIRM',
    PROCESSING = 'PROCESSING',
    CREATED = 'CREATED',
}
