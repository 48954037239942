import {useDispatch} from 'react-redux'
import {MouseEvent} from 'react'
import {closeDeleteModal} from '../../../../../store/state/delete-call-home-request/action-creators'
import * as Styled from './cance-button.styled'
export function CancelButton(): JSX.Element {
    const dispatch = useDispatch()
    function cancelChanges(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        dispatch(closeDeleteModal())
    }
    return (
        <Styled.CancelButton id="cancel-changes-button" onClick={cancelChanges}>
            Cancel
        </Styled.CancelButton>
    )
}
