import {ActionType} from './action-type'
import * as Actions from './actions'

export function toggleTerminalExpanded(isExtended: boolean): Actions.ToggleTerminalExpanded {
    return {type: ActionType.TOGGLE_TERMINAL_EXPANDED, payload: isExtended}
}

export function setSelectedTabIndex(index: number): Actions.setSelectedTabIndex {
    return {type: ActionType.SET_SELECTED_TAB_INDEX, payload: index}
}

export function showCloseTerminalModal(isShown: boolean): Actions.showCloseTerminalModal {
    return {type: ActionType.SHOW_CLOSE_TERMINAL_MODAL, payload: isShown}
}
