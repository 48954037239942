import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {errorSelector} from '../../../../../store/state/delete-call-home-request/selectors'
import {CancelButton} from './cancel-button/cancel-button'

import * as Styled from './chr-buttons-area.styled'
import {CreateButton} from './create-button/create-button'

export function ChrButtonsArea(): JSX.Element {
    const error = useTypedSelector(errorSelector)
    return (
        <Styled.ButtonsArea>
            <CancelButton />
            {!error && <CreateButton />}
        </Styled.ButtonsArea>
    )
}
