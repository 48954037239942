import {ReactNode, ReactNodeArray, useReducer} from 'react'
import {ChrContext} from './chr-context'
import {chrReducer} from './state/reducer'
import {DEFAULT_CHR_STATE} from './types/default-chr-state'
import {ChrState} from './types/chr-state'

type ChrProviderProps = {
    children: ReactNode | ReactNodeArray
}

export function ChrProvider({children}: ChrProviderProps): JSX.Element {
    const initialState: ChrState = DEFAULT_CHR_STATE

    const [state, dispatch] = useReducer(chrReducer, initialState)

    return <ChrContext.Provider value={{state, dispatch}}>{children}</ChrContext.Provider>
}
