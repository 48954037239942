import styled from 'styled-components'
import {smallFont} from '../../../../theme/font-styles'
import {spacing} from '../../../../theme/spacing'

export const SwitchBox = styled.div`
    color: ${(props) => props.theme.colors.text.default};
    ${(props) => props.theme.font.weight.semibold};
`

export const SwitchToggle = styled.div`
    display: flex;
    /* justify-content: flex-end; */
    align-items: center;
    ${(props) => props.theme.font.weight.semibold};
`

export const MiniPadding = styled.span`
    ${smallFont()};
    ${(props) => props.theme.font.weight.normal};
    padding: 3px ${spacing(1)};
`
interface ToggleButtonProps {
    selectable?: boolean
}

export const ToggleButton = styled.div<ToggleButtonProps>`
    cursor: ${(props) => (props.selectable ? 'pointer' : 'inherit')};
`
