import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {errorSelector} from '../../../../../store/state/delete-call-home-request/selectors'
import {CancelButton} from '../cancel-button/cancel-button'
import {DeleteButton} from '../delete-button/delete-button'
import * as Styled from './chr-actions.styled'
export function ChrActions(): JSX.Element {
    const error = useTypedSelector(errorSelector)
    return (
        <>
            <Styled.Title>Are you sure you want to delete the selected CHR?</Styled.Title>
            <Styled.ButtonsArea>
                <CancelButton />
                {!error && <DeleteButton />}
            </Styled.ButtonsArea>
        </>
    )
}
