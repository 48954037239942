import {RefreshCcw as RefreshIcon} from 'react-feather'
import {useDispatch} from 'react-redux'
import {fetchCallHomeRequests} from '../../../../../store/state/call-home-request/action-creators'
import * as Styled from './refresh-button.styled'
export function RefreshButton(): JSX.Element {
    const dispatch = useDispatch()
    return (
        <Styled.Button
            id="refresh-button"
            onClick={() => {
                dispatch(fetchCallHomeRequests())
            }}>
            <RefreshIcon height={15} width={15} />
            Refresh Data
        </Styled.Button>
    )
}
