import styled from 'styled-components'
import {spacing} from '../../theme/spacing'
import {BREAK_POINT_MINI_LOGO} from './data-helpers'

interface DisplayAreaProps {
    width: number | undefined
}

export const DisplayArea = styled.div<DisplayAreaProps>`
    display: flex;
    flex-direction: ${(props) =>
        props.width && props.width > BREAK_POINT_MINI_LOGO ? 'row' : 'column'};
    justify-content: space-between;
    gap: ${spacing(1)};
    padding: ${spacing(4)};
    background-color: ${(props) => props.theme.chrList.dataArea.background};
`
