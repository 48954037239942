import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'

export const ListToggleWrapper = styled.div`
    display: flex;
    margin-top: 1rem;
    margin-left: 2.2rem;
    margin-bottom: 1rem;
    button {
        display: flex;
        color: ${(props) => props.theme.colors.text.default};
        ${smallFont()}
        font-weight: ${(props) => props.theme.font.weight.extrabold};
        background: none;
        border: none;
    }
    svg {
        height: 1rem;
        width: 1rem;
    }
`
