import {Trash2 as DeleteIcon} from 'react-feather'
import {useDispatch} from 'react-redux'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {displayDeleteModal} from '../../../../../store/state/delete-call-home-request/action-creators'
import {GuidType} from '../../../../../values/generic-type-defintions'
import * as Styled from '../_styled/button-cell.styled'
import {BREAK_POINT_BUTTON_REDUCED_TEXT} from '../_styled/button-cell.styled'

interface Props {
    identity: GuidType
    isTerminalExpanded: boolean
}
export function DeleteButtonCell({identity, isTerminalExpanded}: Props): JSX.Element {
    const dispatch = useDispatch()
    const {width} = useDimensions()

    const text =
        width && width > BREAK_POINT_BUTTON_REDUCED_TEXT && !isTerminalExpanded
            ? 'Delete CHR'
            : 'Delete'
    return (
        <Styled.Button
            width={width}
            id="delete-button-cell"
            onClick={() => {
                dispatch(displayDeleteModal(identity))
            }}>
            <DeleteIcon height={14} width={14} />
            {text}
        </Styled.Button>
    )
}
