import {OwlLogo} from '../../../pages/login-v2/components/header/owl-logo'
import * as Styles from './_styles/logo.styled'

export function Logo(): JSX.Element {
    return (
        <Styles.Logo>
            <OwlLogo scale={0.6} />
        </Styles.Logo>
    )
}
