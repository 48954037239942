/* eslint-disable no-console */
import ActionType from './action-type'
import {Action} from './actions'
import produce from 'immer'
import {LoggingOutAction} from '../session-data/actions'
import {DEFAULT_CHR_FILTER_STATE, CHRFilterReduxState} from './state'

const filterReducer = produce(
    (draft: CHRFilterReduxState = DEFAULT_CHR_FILTER_STATE, action: Action | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.SET_FILTER_STATE:
                draft.onlyUserCHR = action.payload
                break

            /* istanbul ignore next */
            default:
                return draft
        }
        return draft
    },
)
export default filterReducer
