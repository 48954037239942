import * as Styled from '../_styled/button-cell.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {ChevronsLeft} from 'react-feather'
import {useChr} from '../../../contexts/use-chr'

export function MinimiseTerminalButtonCell(): JSX.Element {
    const {width} = useDimensions()
    const {toggleTerminalExpanded} = useChr()

    return (
        <Styled.Button
            width={width}
            id="minimise-terminal-button-cell"
            onClick={() => toggleTerminalExpanded(false)}>
            <ChevronsLeft height={14} width={14} />
            Minimise
        </Styled.Button>
    )
}
