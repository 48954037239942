import produce from 'immer'
// import {clone} from 'lodash'
import {ChrState} from '../types/chr-state'
import {ActionType} from './action-type'
import {AllActions} from './actions'
// import SessionActionType from '../../../../store/state/session-data/action-type'
// import {DEFAULT_CHR_STATE} from '../types/default-chr-state'
// import {LoggingOutAction} from '../../../../store/state/session-data/actions'

export const chrReducer = produce((draft: ChrState, action: AllActions) => {
    switch (action.type) {
        case ActionType.TOGGLE_TERMINAL_EXPANDED:
            draft.terminalExpanded = action.payload
            break
        case ActionType.SET_SELECTED_TAB_INDEX:
            draft.selectedTabIndex = action.payload
            break
        case ActionType.SHOW_CLOSE_TERMINAL_MODAL:
            draft.showCloseTerminalModal = action.payload
            break
    }
    return draft
})
