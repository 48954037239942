import {Overlay} from '../shared/overlay'
import {CreateCallHomeRequests} from './components/create-chr-form'

export function CreateCallHomeRequestsModal(): JSX.Element {
    return (
        <Overlay
            onClickFn={(e) => {
                e && e.preventDefault()
            }}
            z={900}>
            <CreateCallHomeRequests />
        </Overlay>
    )
}
