import * as Styled from './create-chr-form.styled'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {SelectLocationEntry} from './select-location-entry/select-location-entry'
import {isProcessingSelector} from '../../../../store/state/create-call-home-request/selectors'
import {CreationInProgress} from './creation-in-progress/creation-in-progress'
import {ChrButtonsArea} from './chr-buttons-area/chr-buttons-area'
import {SelectEnvironmentEntry} from './select-environment-entry/select-environment-entry'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {FilterByVesselName} from './filter-vessel-name/filter-vessel-name'

export function CreateCallHomeRequests(): JSX.Element {
    const isLoading = useTypedSelector(isProcessingSelector)
    const {width} = useDimensions()

    return (
        <Styled.Section onClick={(e) => e.stopPropagation()}>
            <Styled.Header>
                <Styled.Title>Create Call Home Request</Styled.Title>
            </Styled.Header>
            <Styled.Settings width={width}>
                <Styled.Title>Select vessel for which you want to create CHR</Styled.Title>
                <Styled.Filters width={width}>
                    <SelectEnvironmentEntry />
                    <Styled.LocationFiltersWrapper width={width}>
                        <SelectLocationEntry />
                        <FilterByVesselName />
                    </Styled.LocationFiltersWrapper>
                </Styled.Filters>
            </Styled.Settings>
            <Styled.Actions>
                {isLoading ? <CreationInProgress /> : <ChrButtonsArea />}
            </Styled.Actions>
        </Styled.Section>
    )
}
