import styled from 'styled-components'

interface LabelProps {
    gridArea: string
}

export const Label = styled.label<LabelProps>`
    margin-bottom: 0;
    display: inline-block;
    grid-area: ${(props) => props.gridArea};
`
