import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {CallHomeRequests} from '../../../../values/call-home-request'
import {formatDate, getFormattedParam} from '../../data-helpers'
import {CardDataCell} from './card-data-cell'
import * as Styled from './_styled/tab-info-card.styled'

interface TabInfoProps {
    callHomeRequest: CallHomeRequests
}

export function TabInfoCard({callHomeRequest}: TabInfoProps): JSX.Element {
    const {width} = useDimensions()
    return (
        <Styled.Card id={`chr-card_${callHomeRequest.identity}`}>
            <Styled.CardAreaTitle width={width}>
                <Styled.CardTitleData
                    id={`chr-location_${callHomeRequest.identity}`}
                    gridArea="LOCATION">
                    {getFormattedParam(callHomeRequest.locationCode)}
                </Styled.CardTitleData>
                <Styled.CardTitleData id={`chr-port_${callHomeRequest.identity}`} gridArea="PORT">
                    {getFormattedParam(callHomeRequest.portNumber)}
                </Styled.CardTitleData>
            </Styled.CardAreaTitle>
            <Styled.CardAreaContent width={width}>
                <CardDataCell
                    gridArea="STATUS"
                    label="Status:"
                    callHomeRequest={callHomeRequest}
                    identifier="status">
                    {getFormattedParam(callHomeRequest.status)}
                </CardDataCell>
                <CardDataCell
                    gridArea="WHO"
                    label="User:"
                    callHomeRequest={callHomeRequest}
                    identifier="user">
                    {getFormattedParam(callHomeRequest.user.name)}
                </CardDataCell>
                <CardDataCell
                    gridArea="WHEN"
                    label="When:"
                    callHomeRequest={callHomeRequest}
                    identifier="when">
                    {formatDate(callHomeRequest.updatedAt)}
                </CardDataCell>
            </Styled.CardAreaContent>
        </Styled.Card>
    )
}
