import {MouseEvent} from 'react'
import {useChr} from '../../../../../pages/chr-listing/contexts/use-chr'
import * as Styled from './close-button.styled'

export function CloseButton(): JSX.Element {
    const {setShowCloseTerminalModal, toggleTerminalExpanded} = useChr()

    function closeTerminal(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        setShowCloseTerminalModal(false)
        toggleTerminalExpanded(false)
    }

    return <Styled.Button onClick={closeTerminal}>Minimise Terminal</Styled.Button>
}
