import styled from 'styled-components'

export const Grid = styled.div`
    display: inline-grid;
    grid-column-gap: 30px;
    grid-row-gap: 6px;
    grid-template: 'vl vt' 'avl avt' 'ipl ipv';
    font-size: 12px;
    line-height: 17px;
    color: ${(props) => props.theme.colors.text.default};
`
