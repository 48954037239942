import styled from 'styled-components'
import {mediumFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const ButtonsArea = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: ${spacing(4)};
`
export const Title = styled.h1`
    ${mediumFont()}
    margin: 0;
    padding: 0;
    text-align: center;
`
