import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {FilterSwitch} from '../filter-switch/filter-switch'
import {CreateChrButton} from './create-chr-button/create-chr-button'
import * as Styled from './data-actions.styled'
import {RefreshButton} from './refresh/refresh-button'
export function DataActions(): JSX.Element {
    const {width} = useDimensions()

    return (
        <Styled.Container width={width}>
            <CreateChrButton />
            <FilterSwitch />
            <RefreshButton />
        </Styled.Container>
    )
}
