interface OwlLogoProps {
    scale?: number
}

export function OwlLogo({scale = 1}: OwlLogoProps): JSX.Element {
    const height = 62
    const width = 43
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width * scale}
            height={height * scale}
            viewBox={`0 0 ${width} ${height}`}>
            <g id="Group_10665" data-name="Group 10665" transform="translate(0)">
                <g id="Group_10664" data-name="Group 10664">
                    <path
                        id="Path_3861"
                        data-name="Path 3861"
                        d="M-788.535-2587.784c-2.477,0-4.538,2.4-4.964,5.572-.1.7.583,1.346,1.6,1.539l1.025.194a12.686,12.686,0,0,0,4.682,0l1.025-.194c1.016-.193,1.694-.837,1.6-1.539C-784-2585.38-786.056-2587.784-788.535-2587.784Z"
                        transform="translate(817.29 2642.049)"
                        fill="#fff"
                    />
                    <path
                        id="Path_3862"
                        data-name="Path 3862"
                        d="M-795.835-2587.784c-2.477,0-4.538,2.4-4.964,5.572-.1.7.583,1.346,1.6,1.539l1.025.194a12.687,12.687,0,0,0,4.682,0l1.025-.194c1.016-.193,1.694-.837,1.6-1.539C-791.3-2585.38-793.356-2587.784-795.835-2587.784Z"
                        transform="translate(810.694 2642.049)"
                        fill="#fff"
                    />
                    <path
                        id="Path_3863"
                        data-name="Path 3863"
                        d="M-779.087-2600.875a11.568,11.568,0,0,1-3.007,2.476l-.028.018a10.677,10.677,0,0,1-1.235.6,10,10,0,0,1-2.676.672,2.589,2.589,0,0,0-2.11,1.726c-.431,1.2-.35,2.851.714,5.126l.183.31c1.91,3.364-.839,9.311-2.3,11.63a7.121,7.121,0,0,1,2.016,1.566c4.359-1.732,9.539-10.282,9.539-23.923a.23.23,0,0,0-.03-.088A.632.632,0,0,0-779.087-2600.875Z"
                        transform="translate(820.909 2630.398)"
                        fill="#fff"
                    />
                    <path
                        id="Path_3864"
                        data-name="Path 3864"
                        d="M-794.066-2578.32c-1.46-2.319-4.208-8.267-2.3-11.63l.183-.31c1.066-2.275,1.147-3.922.716-5.126a2.59,2.59,0,0,0-2.112-1.726,9.988,9.988,0,0,1-2.674-.672,10.622,10.622,0,0,1-1.235-.6l-.028-.018a11.6,11.6,0,0,1-3.009-2.476.632.632,0,0,0-1.068.11.23.23,0,0,0-.028.088c0,13.641,5.179,22.191,9.54,23.923A7.125,7.125,0,0,1-794.066-2578.32Z"
                        transform="translate(806.332 2630.398)"
                        fill="#fff"
                    />
                    <path
                        id="Path_3865"
                        data-name="Path 3865"
                        d="M-788.419-2598.2l-.008-.006.008-.011h-.024a12.775,12.775,0,0,1-2.719-2.445,10.5,10.5,0,0,1-1.409-2.217,10.442,10.442,0,0,1-1.409,2.217,12.8,12.8,0,0,1-2.713,2.445h-.024l.007.011-.007.006h.011c.15.208,1.883,3.938,2.841,5.587.944,1.621,1.7,1.763,2.6-.005.853-1.694,2.691-5.374,2.839-5.582Z"
                        transform="translate(814.376 2628.7)"
                        fill="#fff"
                    />
                    <path
                        id="Path_3866"
                        data-name="Path 3866"
                        d="M-764.478-2605.036a14.589,14.589,0,0,0-1.768-2.366,13.731,13.731,0,0,0-2.1-1.863,17.368,17.368,0,0,0,2.23-3.1,16.042,16.042,0,0,0,1.457-3.493.544.544,0,0,0-.231-.616.561.561,0,0,0-.666.022,25.615,25.615,0,0,1-5.641,3.507,23.259,23.259,0,0,1-7.289,1.664,8.31,8.31,0,0,0-3.558,1.138,4.873,4.873,0,0,0-1.351,1.111.938.938,0,0,1-.8.452.936.936,0,0,1-.8-.452,4.874,4.874,0,0,0-1.35-1.111,8.31,8.31,0,0,0-3.558-1.138,23.257,23.257,0,0,1-7.289-1.664,25.609,25.609,0,0,1-5.641-3.507.561.561,0,0,0-.666-.022.544.544,0,0,0-.231.616,15.964,15.964,0,0,0,1.457,3.493,17.375,17.375,0,0,0,2.231,3.1,13.719,13.719,0,0,0-2.1,1.863,14.582,14.582,0,0,0-1.768,2.366,14.232,14.232,0,0,0-2.022,8.749,10.754,10.754,0,0,0,3.626,7.2l0,.006.006,0c.154.127.311.253.472.374s.326.234.5.343a9.737,9.737,0,0,0,8.34.946,13.4,13.4,0,0,0,7.27-5.959c.176-.293.339-.589.493-.887s.294-.6.425-.9c.078-.18.438-.272.6-.272s.528.092.605.272c.131.3.271.605.425.9s.317.594.493.887a13.405,13.405,0,0,0,7.27,5.959,9.736,9.736,0,0,0,8.34-.946c.171-.109.336-.226.5-.343s.318-.246.472-.374l.006,0,0-.006a10.238,10.238,0,0,0,3.147-4.847,12.264,12.264,0,0,0,.48-2.357A14.235,14.235,0,0,0-764.478-2605.036Zm-28.87,12.106a7.514,7.514,0,0,1-7.559-7.467,7.513,7.513,0,0,1,7.559-7.467,7.572,7.572,0,0,1,6.244,3.264,7.467,7.467,0,0,1,1.293,4.2,7.463,7.463,0,0,1-1.293,4.2A7.575,7.575,0,0,1-793.348-2592.929Zm10.771-7.467a7.467,7.467,0,0,1,1.294-4.2,7.571,7.571,0,0,1,6.244-3.264,7.513,7.513,0,0,1,7.558,7.467,7.514,7.514,0,0,1-7.558,7.467,7.569,7.569,0,0,1-6.244-3.264A7.464,7.464,0,0,1-782.576-2600.4Z"
                        transform="translate(806.001 2616.573)"
                        fill="#fff"
                    />
                    <path
                        id="Path_3867"
                        data-name="Path 3867"
                        d="M-787.574-2601.325a4.512,4.512,0,0,0,4.512-4.511,4.511,4.511,0,0,0-4.512-4.512,4.51,4.51,0,0,0-4.51,4.512A4.51,4.51,0,0,0-787.574-2601.325Zm-.731-6.518a2.127,2.127,0,0,1,.731-.148,2.156,2.156,0,0,1,2.155,2.155,2.153,2.153,0,0,1-2.155,2.153,2.152,2.152,0,0,1-2.153-2.153,2.088,2.088,0,0,1,.3-1.032A2.117,2.117,0,0,1-788.3-2607.843Z"
                        transform="translate(818.565 2622.084)"
                        fill="#fff"
                    />
                    <path
                        id="Path_3868"
                        data-name="Path 3868"
                        d="M-797.226-2610.348a4.511,4.511,0,0,0-4.512,4.512,4.512,4.512,0,0,0,4.512,4.511,4.51,4.51,0,0,0,4.51-4.511A4.509,4.509,0,0,0-797.226-2610.348Zm-2.155,4.512a2.1,2.1,0,0,1,.358-1.119,1.621,1.621,0,0,0,.74-.72,2.091,2.091,0,0,1,1.057-.317,2.154,2.154,0,0,1,2.153,2.155,2.152,2.152,0,0,1-2.153,2.153A2.153,2.153,0,0,1-799.381-2605.836Z"
                        transform="translate(809.85 2622.084)"
                        fill="#fff"
                    />
                </g>
            </g>
        </svg>
    )
}
