import {CloseTerminalModal} from '../../components/modals/close-terminal/close-terminal-modal'
import {CreateCallHomeRequestsModal} from '../../components/modals/create-chr/create-call-home-request-modal'
import {DeleteCallHomeRequestsModal} from '../../components/modals/delete-chr/delete-call-home-request-modal'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../hooks/use-typed-selector'
import {isChrCreatingSelector} from '../../store/state/create-call-home-request/selectors'
import {hasAnyIdToDeleteSelector} from '../../store/state/delete-call-home-request/selectors'
import {ContentContainerTop} from '../../templates/fixed-page/content-container-top.styled'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
// import {ChrListCards} from './components/cards/chr-list-cards'
import {DataActions} from './components/data-actions/data-actions'
import {ChrListTable} from './components/table/chr-list-table'
import {useChr} from './contexts/use-chr'
// import {showCards} from './data-helpers'

export function ChrListPage(): JSX.Element {
    const {width} = useDimensions()
    const showCreateModal = useTypedSelector(isChrCreatingSelector)
    const showDeleteModal = useTypedSelector(hasAnyIdToDeleteSelector)
    const {showCloseTerminalModal} = useChr()

    return (
        <FixedPageTemplate>
            <ContentContainerTop width={width}>
                <DataActions />
            </ContentContainerTop>
            <ChrListTable />
            {showCreateModal && <CreateCallHomeRequestsModal />}
            {showDeleteModal && <DeleteCallHomeRequestsModal />}
            {showCloseTerminalModal && <CloseTerminalModal />}
        </FixedPageTemplate>
    )
}
