import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import {REST} from '../../..'
import {Api} from '../../../api/api'
import {CallHomeRequests} from '../../../values/call-home-request'

const CALL_HOME_REQUESTS_REST_URL = 'api/v1/chrs'

export function fetchCallHomeRequests(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch) => {
        dispatch(requestCallHomeRequestAction())

        REST.get(CALL_HOME_REQUESTS_REST_URL).then((response) => {
            dispatch(receiveCallHomeRequestAction(response.data.data))
        })
    }
}

function requestCallHomeRequestAction(): Actions.RequestCallHomeRequestsAction {
    return {
        type: ActionType.REQUEST_CALL_HOME_REQUESTS,
    }
}

function receiveCallHomeRequestAction(
    callHomeRequests: CallHomeRequests[],
): Actions.ReceiveCallHomeRequestsAction {
    return {
        type: ActionType.RECEIVE_CALL_HOME_REQUESTS,
        payload: callHomeRequests,
    }
}
