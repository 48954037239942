import {useState} from 'react'
import {ChevronUp, ChevronDown} from 'react-feather'
import {CallHomeRequests} from '../../../../values/call-home-request'
import {PendingChrs} from '../pending-chrs/pending-chrs'
import {ListToggleWrapper} from './_styled/pending-toggle.styled'

interface Props {
    pendingRequests: CallHomeRequests[]
    reducedPending: CallHomeRequests[]
    breakpointMet: boolean | null
    terminalExpanded: boolean
    selectedTabIndex: number
    clickOutside: React.RefObject<HTMLDivElement>
    onlyUserCHR: boolean
}

export function PendingToggle({
    pendingRequests,
    reducedPending,
    breakpointMet,
    terminalExpanded,
    selectedTabIndex,
    clickOutside,
    onlyUserCHR,
}: Props): JSX.Element {
    const [pendingToggleOn, setPendingToggleState] = useState(true)

    function handleClick() {
        setPendingToggleState(!pendingToggleOn)
    }

    return (
        <>
            <ListToggleWrapper>
                <button onClick={handleClick}>
                    Pending Connections {pendingToggleOn ? <ChevronDown /> : <ChevronUp />}
                </button>
            </ListToggleWrapper>
            {pendingToggleOn ? (
                <PendingChrs
                    pendingRequests={pendingRequests}
                    reducedPending={reducedPending}
                    breakpointMet={breakpointMet}
                    terminalExpanded={terminalExpanded}
                    selectedTabIndex={selectedTabIndex}
                    clickOutside={clickOutside}
                    onlyUserCHR={onlyUserCHR}
                />
            ) : (
                ''
            )}
        </>
    )
}
