import styled from 'styled-components'
import {ButtonStyled} from '../../../../../button/button.styled'

export const Button = styled(ButtonStyled)`
    font-weight: ${(props) => props.theme.font.weight.semibold};
    justify-content: center;
    text-transform: uppercase;
    min-width: 110px;
    :disabled {
        opacity: 0.5;
    }
`
