import {Move} from 'react-feather'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {useChr} from '../../../contexts/use-chr'
import * as Styled from '../_styled/button-cell.styled'

interface Props {
    index: number
}
export function SelectTabButtonCell({
    // isModalExpanded,
    index,
}: Props): JSX.Element {
    const {width} = useDimensions()
    const text = 'Select'
    const {setSelectedTabIndex} = useChr()

    return (
        <Styled.Button
            width={width}
            id="select-button-cell"
            onClick={() => setSelectedTabIndex(index)}>
            <Move height={14} width={14} />
            {text}
        </Styled.Button>
    )
}
