import {createSelector} from 'reselect'
import {isFetchingCallHomeRequestsSelector} from '../../../store/state/call-home-request/selectors'
import {fetchingStatusSelector} from '../../../store/state/config/selectors'
import {ConfigFetchingStatus} from '../../../store/state/config/state'

export const allDataIsLoadedReselector = createSelector(
    fetchingStatusSelector,
    isFetchingCallHomeRequestsSelector,
    (configFetchingStatus, fetchingCallHomeRequests): boolean => {
        return configFetchingStatus !== ConfigFetchingStatus.INITIALISED || fetchingCallHomeRequests
            ? false
            : true
    },
)
