import {ChevronsRight} from 'react-feather'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import * as Styled from '../_styled/button-cell.styled'
import {BREAK_POINT_BUTTON_REDUCED_TEXT} from '../_styled/button-cell.styled'
import {useChr} from '../../../contexts/use-chr'

interface OpenTerminalButtonCellProps {
    index: number
}

export function OpenTerminalButtonCell({index}: OpenTerminalButtonCellProps): JSX.Element {
    const {width} = useDimensions()
    const {setSelectedTabIndex, toggleTerminalExpanded, terminalExpanded} = useChr()
    const text =
        width && width > BREAK_POINT_BUTTON_REDUCED_TEXT && !terminalExpanded
            ? 'Open Terminal'
            : 'Open'

    const onClickWrapper = () => {
        toggleTerminalExpanded(true)
        setSelectedTabIndex(index)
    }

    return (
        <Styled.Button width={width} id="open-terminal-button-cell" onClick={onClickWrapper}>
            <ChevronsRight height={14} width={14} />
            {text}
        </Styled.Button>
    )
}
