import * as Styles from './_styles/nav-bar-links.styled'
import {NavBarLink} from './nav-bar-link'

export function NavBarLinks(): JSX.Element {
    return (
        <Styles.NavBarLinks>
            <NavBarLink id="navigation-listing" path="/list" navLinkText="Call Home Requests" />
            <NavBarLink id="swagger" path="/swagger" navLinkText="Swagger" />
        </Styles.NavBarLinks>
    )
}
