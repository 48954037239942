import {useTheme} from 'styled-components'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {ContentContainerMiddle} from '../../../../templates/fixed-page/content-container-middle.styled'
import {useChr} from '../../contexts/use-chr'
import {ChrListTableContent} from './chr-list-table-content'
import {TableHeader} from './header/table-header'

export function ChrListTable(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()
    const {terminalExpanded} = useChr()

    return (
        <>
            <ContentContainerMiddle
                width={width}
                backgroundColor={theme.chrList.dataArea.background}>
                <TableHeader showTerminal={terminalExpanded} />
            </ContentContainerMiddle>

            <ContentContainerMiddle
                width={width}
                backgroundColor={theme.chrList.dataArea.background}
                scrollable>
                <ChrListTableContent />
            </ContentContainerMiddle>
        </>
    )
}
