import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {authTokenRetrieved} from '../../../../store/state/auth/action-creators'
import {oauthClientIdsSelector} from '../../../../store/state/config/selectors'
import {CredentialResponse, GoogleLogin} from '@react-oauth/google'
import {validString} from '../../../../utils/Utils'

export function AdminSignInButton(): JSX.Element | null {
    const dispatch = useDispatch()

    function responseGoogleSuccess(tokenResponse: CredentialResponse): void {
        const authToken = tokenResponse.credential
        if (!validString(authToken)) {
            throw new Error('Credentals response was empty')
        }
        dispatch(authTokenRetrieved(authToken as string))
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function responseGoogleFailure(response: string): void {
        // eslint-disable-next-line no-console
        console.error(response)
        throw new Error(`Failure during login: error: ${response} (Details: ${response})`)
    }

    const oauthClientIds = useTypedSelector(oauthClientIdsSelector)
    const isLoginEnabled = oauthClientIds !== undefined

    return isLoginEnabled && oauthClientIds.google ? (
        <GoogleLogin
            onSuccess={responseGoogleSuccess}
            onError={() => responseGoogleFailure('Login Failed')}
        />
    ) : null
}
