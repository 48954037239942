import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {ButtonStyled} from '../../../../button/button.styled'

export const Button = styled(ButtonStyled)`
    font-weight: ${(props) => props.theme.font.weight.semibold};
    justify-content: center;
    text-transform: uppercase;
    min-width: 110px;
`

export const Error = styled.div`
    padding: 7px 13px;
    ${smallFont()}
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    min-width: 110px;
    text-align: center;
`
