import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'

export const Text = styled.div`
    background: none;
    border: none;
    padding: 7px 13px;
    ${mediumSmallFont()}
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    text-transform: uppercase;
    min-width: 110px;
    text-align: center;
`
