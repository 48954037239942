import ActionType from './action-type'
import {Action} from './actions'

import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import {CreateCallHomeRequestReduxState, DEFAULT_CREATE_CALL_HOME_REQUEST_STATE} from './state'
import {CreationStatus, ProcessingStatus} from '../../../values/call-home-request'

export default function createCallHomeRequestReducer(
    state: CreateCallHomeRequestReduxState = DEFAULT_CREATE_CALL_HOME_REQUEST_STATE,
    action: Action | LoggingOutAction,
): CreateCallHomeRequestReduxState {
    switch (action.type) {
        case ActionType.DISPLAY_CREATE_MODAL:
            return {
                ...state,
                locationCode: '',
                creationChrStatus: CreationStatus.ENTER_DETAILS,
            }
        case ActionType.CLOSE_CREATE_MODAL:
            return {
                ...state,
                creationChrStatus: CreationStatus.NOT_CREATING,
                environment: '',
            }
        case ActionType.PROCESSING_CALL_HOME_REQUEST:
            return {
                ...state,
                isProcessing: ProcessingStatus.PROCESSING,
            }
        case ActionType.CREATE_CALL_HOME_REQUEST:
            return {
                ...state,
                error: '',
                locationCode: action.payload,
            }
        case ActionType.CALL_HOME_REQUEST_CREATED:
            return {
                ...state,
                isProcessing: ProcessingStatus.PROCESSED,
                creationChrStatus: CreationStatus.CREATED,
                environment: '',
            }
        case ActionType.SET_ERROR:
            if (state.error === action.payload) {
                return state
            }
            return {
                ...state,
                error: action.payload,
                isProcessing: ProcessingStatus.NOT_PROCESSING,
            }
        case ActionType.SET_ENVIRONMENT_FILTER:
            return {
                ...state,
                locationCode: '',
                environment: action.payload,
            }
        case SessionActionType.LOGGING_OUT:
            return DEFAULT_CREATE_CALL_HOME_REQUEST_STATE
        /* istanbul ignore next */
        default:
            return state
    }
}
