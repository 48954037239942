import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'

interface ContainerProps {
    width: number | undefined
}

export const Container = styled.div<ContainerProps>`
    padding: ${spacing(4)};
    background-color: ${(props) => props.theme.chrList.dataArea.background};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    row-gap: ${spacing(2)};
`
