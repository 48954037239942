import styled from 'styled-components'

export const TerminalArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.background.default};
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
`
