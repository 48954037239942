import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {ChangeEvent} from 'react'
import {setEnvironmentAction} from '../../../../../store/state/create-call-home-request/action-creators'
import {environmentSelector} from '../../../../../store/state/create-call-home-request/selectors'
import {useDispatch} from 'react-redux'
import {Select} from './select-environment-entry.styled'
import {sortedEnvironmentsSelector} from '../../selectors/sorted-environments-selector'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'

export function SelectEnvironmentEntry(): JSX.Element {
    const dispatch = useDispatch()
    const {width} = useDimensions()
    const environments = useTypedSelector(sortedEnvironmentsSelector)
    const selectedEnvironment = useTypedSelector(environmentSelector)
    function environmentChangeHandler(e: ChangeEvent<HTMLSelectElement>) {
        dispatch(setEnvironmentAction(e.target.value))
    }
    return (
        <Select
            onChange={environmentChangeHandler}
            value={selectedEnvironment}
            id="environment-entry"
            width={width}>
            <option key="default-option" value="">
                Select Environment
            </option>
            {environments?.map((value) => (
                <option key={value} value={value}>
                    {value.toUpperCase()}
                </option>
            ))}
        </Select>
    )
}
