import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {BREAK_POINT} from '../create-chr-form.styled'

interface SelectProps {
    width: number | undefined
}

export const Select = styled.select<SelectProps>`
    text-align-last: center;
    padding: 3px ${spacing(4)} 3px ${spacing(6)};
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    font-size: 12px;
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    border-radius: 3px;
    width: ${(props) => ((props.width || 0) > BREAK_POINT ? '200px' : '250px')};
    height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`
