import {CreationStatus, ProcessingStatus} from '../../../values/call-home-request'

export interface CreateCallHomeRequestReduxState {
    creationChrStatus: CreationStatus
    error: string
    isProcessing: ProcessingStatus
    locationCode: string
    environment: string
    description: string
}

export const DEFAULT_CREATE_CALL_HOME_REQUEST_STATE: CreateCallHomeRequestReduxState = {
    creationChrStatus: CreationStatus.NOT_CREATING,
    error: '',
    isProcessing: ProcessingStatus.NOT_PROCESSING,
    locationCode: '',
    environment: '',
    description: '',
}
