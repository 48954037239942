import {CallHomeRequests} from '../../../../../values/call-home-request'
import {Grid} from './grid.styled'
import {Label} from './label.styled'
import {Overview} from './overview.styled'
import {Value} from './value.styled'

interface ChrDetailsProps {
    callHomeRequest: CallHomeRequests
}
export function ChrDetails({callHomeRequest}: ChrDetailsProps): JSX.Element | null {
    if (!callHomeRequest) {
        return null
    }
    return (
        <Overview>
            <Grid>
                <Label gridArea="vl">Vessel</Label>
                <Label gridArea="avl">Port</Label>
                <Label gridArea="ipl">Status</Label>
                <>
                    <Value gridArea="vt" id="asset_overview_location">
                        {callHomeRequest?.locationCode ?? 'Unknown'}
                    </Value>
                    <Value gridArea="avt" id="asset_overview_value">
                        {callHomeRequest?.portNumber ?? 'Unknown'}
                    </Value>
                    <Value gridArea="ipv" id="asset_overview_ip">
                        {callHomeRequest?.status ?? 'Unknown'}
                    </Value>
                </>
            </Grid>
        </Overview>
    )
}
