import styled from 'styled-components'

interface TerminalTabWrapperProps {
    terminalExpanded: boolean
}

export const TerminalTabWrapper = styled.div<TerminalTabWrapperProps>`
    width: ${(props) => (props.terminalExpanded ? '100%' : '0px')};
    height: ${(props) => (props.terminalExpanded ? '100%' : '0px')};
    position: ${(props) => (props.terminalExpanded ? 'relative' : 'absolute')};
    visibility: ${(props) => (props.terminalExpanded ? 'visible' : 'hidden')};
`

interface FlexWrapperProps {
    terminalExpanded: boolean
}
export const FlexWrapper = styled.div<FlexWrapperProps>`
    flex: ${(props) => (props.terminalExpanded ? '0' : '1')};
`
