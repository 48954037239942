import * as Styled from './token-area.styled'

import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {authTokenSelector} from '../../../../store/state/auth/selectors'
import {Copy} from 'react-feather'
import {IconText} from './token-area.styled'
import {miniLogoDisplay} from '../../data-helpers'

export function TokenArea(): JSX.Element {
    const authToken = `Bearer ${useTypedSelector(authTokenSelector)}`
    const {width} = useDimensions()
    const wideSize = miniLogoDisplay(width)
    return (
        <Styled.TokenAreaWrapper>
            <Styled.Header>Auth Token</Styled.Header>
            <Styled.AuthTokenValueArea>
                <Styled.AuthTokenValue width={width}>{authToken}</Styled.AuthTokenValue>
                <Styled.CopyButton
                    id="auth-token-copy-button"
                    onClick={() => {
                        navigator.clipboard.writeText(authToken)
                    }}>
                    <Copy />
                    {wideSize && <IconText>Copy</IconText>}
                </Styled.CopyButton>
            </Styled.AuthTokenValueArea>
        </Styled.TokenAreaWrapper>
    )
}
