import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'
import {getGridTemplateColumns, getReducedGridTemplateColumns} from '../../../data-helpers'

interface TableHeaderGridProps {
    showTerminal: boolean | undefined
}

export const TableHeaderGrid = styled.div<TableHeaderGridProps>`
    display: grid;
    grid-template-columns: ${(props) =>
        props.showTerminal ? getReducedGridTemplateColumns() : getGridTemplateColumns()};
    padding: 0 ${spacing(4)} 0 ${spacing(4)};
    grid-template-rows: 48px;
    column-gap: ${spacing(2)};
    background-color: ${(props) => props.theme.chrList.dataArea.background};
    @media (max-width: 1240px) {
        grid-template-columns: ${getReducedGridTemplateColumns()};
    }
`

interface TableHeaderCellProps {
    gridColumn: number
    centeredColumn?: boolean
}
export const TableHeaderCell = styled.div<TableHeaderCellProps>`
    grid-column: ${(props) => props.gridColumn};
    align-self: center;
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    ${(props) => (props.centeredColumn ? 'display: flex; justify-content: center;' : '')}
`
