import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../../../store/state/locations/selectors'
import {ChangeEvent, RefObject, useEffect, useRef} from 'react'
import * as Styled from './filter-vessel-name.styled'
import {X, Search} from 'react-feather'
import {useState} from 'react'
import {VesselNameDropdown} from './vessel-name-dropdown'
import {
    environmentSelector,
    locationCodeSelector,
} from '../../../../../store/state/create-call-home-request/selectors'
import {useOnClickOutside} from '../../../../../hooks/use-on-click-outside'
import {useDispatch} from 'react-redux'
import {populateLocationCodeAction} from '../../../../../store/state/create-call-home-request/action-creators'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'

export function FilterByVesselName(): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useDispatch()
    const locations = useTypedSelector(locationsSelector)
    const selectedEnvironment = useTypedSelector(environmentSelector)
    const selectedLocationCode = useTypedSelector(locationCodeSelector)
    const [searchValue, setSearchValue] = useState('')
    const [isExpanded, setIsExpanded] = useState(false)

    const clickOutside = useRef() as RefObject<HTMLDivElement>
    useOnClickOutside(clickOutside, () => setIsExpanded(false))

    useEffect(() => {
        setSearchValue(
            locations.find((location) => location.code === selectedLocationCode)?.description || '',
        )
    }, [locations, selectedLocationCode])

    const selectedVessels = locations.filter((location) =>
        selectedEnvironment === ''
            ? location.environment
            : location.environment === selectedEnvironment,
    )

    const newLocations = selectedVessels.filter((location) =>
        location.description
            .toLowerCase()
            .replace(/\s/g, '')
            .includes(searchValue.toLowerCase().replace(/\s/g, '')),
    )
    function onChangeHandler(e: ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        setSearchValue(e.target.value)
    }
    function clickCloseHandler() {
        setSearchValue('')
        setIsExpanded(false)
        dispatch(populateLocationCodeAction(''))
    }
    const isEmpty = newLocations.length === 0
    const isCloseDisabled = searchValue.length === 0

    return (
        <div ref={clickOutside}>
            <Styled.Container isExtended width={width}>
                <Styled.SearchIcon>
                    <Search height={15} width={15} />
                </Styled.SearchIcon>
                <Styled.SearchInput
                    id="vessel-name-search"
                    type="text"
                    placeholder=" Vessel Name "
                    value={searchValue}
                    autoComplete="off"
                    onFocus={() => setIsExpanded(true)}
                    onChange={onChangeHandler}
                    isExtended={isExpanded}
                />
                <Styled.CloseIcon disabled={isCloseDisabled} onClick={clickCloseHandler}>
                    <X />
                </Styled.CloseIcon>
            </Styled.Container>
            {isExpanded && (
                <VesselNameDropdown
                    locations={newLocations}
                    setSearchValue={setSearchValue}
                    setIsExpanded={setIsExpanded}
                    isEmpty={isEmpty}
                />
            )}
        </div>
    )
}
