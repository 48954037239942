import {MouseEvent} from 'react'
import {useDispatch} from 'react-redux'

import * as Styled from './create-button.styled'
import {createCallHomeRequest} from '../../../../../../store/state/create-call-home-request/action-creators'
import {locationCodeSelector} from '../../../../../../store/state/create-call-home-request/selectors'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'

export function CreateButton(): JSX.Element | null {
    const dispatch = useDispatch()
    const newLocationCode = useTypedSelector(locationCodeSelector)

    function createChr(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        dispatch(createCallHomeRequest())
    }
    const isCreateDisabled = newLocationCode.length === 0

    return (
        <Styled.Button disabled={isCreateDisabled} id="create-chr-button" onClick={createChr}>
            Create CHR
        </Styled.Button>
    )
}
