import * as Styled from './table-header.styled'
// import {showCards} from '../../../data-helpers'
import {hideHeaders} from '../../../data-helpers'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'

interface TableHeaderProps {
    showTerminal: boolean | undefined
}

export function TableHeader({showTerminal}: TableHeaderProps): JSX.Element {
    const {width} = useDimensions()
    const breakpointMet = hideHeaders(width)

    return (
        <>
            {breakpointMet ? (
                <>
                    <Styled.TableHeaderGrid showTerminal={showTerminal}>
                        <Styled.TableHeaderCell gridColumn={2}>
                            Location Code
                        </Styled.TableHeaderCell>
                        <Styled.TableHeaderCell gridColumn={7} centeredColumn={true}>
                            Actions
                        </Styled.TableHeaderCell>
                    </Styled.TableHeaderGrid>
                </>
            ) : (
                <>
                    <Styled.TableHeaderGrid showTerminal={showTerminal}>
                        <Styled.TableHeaderCell gridColumn={2}>
                            Location Code
                        </Styled.TableHeaderCell>
                        {!showTerminal && (
                            <>
                                <Styled.TableHeaderCell gridColumn={3}>
                                    Port Number
                                </Styled.TableHeaderCell>
                                <Styled.TableHeaderCell gridColumn={4}>
                                    Status
                                </Styled.TableHeaderCell>
                                <Styled.TableHeaderCell gridColumn={5}>User</Styled.TableHeaderCell>
                                <Styled.TableHeaderCell gridColumn={6}>When</Styled.TableHeaderCell>
                                <Styled.TableHeaderCell gridColumn={7} centeredColumn={true}>
                                    Actions
                                </Styled.TableHeaderCell>
                            </>
                        )}
                        {showTerminal && (
                            <>
                                <Styled.TableHeaderCell gridColumn={3} centeredColumn={true}>
                                    Actions
                                </Styled.TableHeaderCell>
                            </>
                        )}
                    </Styled.TableHeaderGrid>
                </>
            )}
        </>
    )
}
