interface TerminalIFrameProps {
    terminalHeight: string
    gottyUrl: string | undefined
}

export function TerminalIFrame({terminalHeight, gottyUrl}: TerminalIFrameProps): JSX.Element {
    return (
        <iframe
            src={gottyUrl}
            frameBorder="0"
            allowFullScreen
            style={{
                position: 'relative',
                width: '100%',
                height: terminalHeight,
                zIndex: 2,
            }}
        />
    )
}
