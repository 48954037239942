import AppState from './app-state'
import {DEFAULT_AUTH_STATE} from '../state/auth/state'
import {DEFAULT_CONFIG_STATE} from '../state/config/state'
import {DEFAULT_SESSION_DATA_STATE} from '../state/session-data/state'
import {DEFAULT_CALL_HOME_REQUESTS_STATE} from '../state/call-home-request/state'
import {DEFAULT_LOCATIONS_STATE} from '../state/locations/state'
import {DEFAULT_DELETE_CALL_HOME_REQUEST_STATE} from '../state/delete-call-home-request/state'
import {DEFAULT_CREATE_CALL_HOME_REQUEST_STATE} from '../state/create-call-home-request/state'
import {DEFAULT_USER_STATE} from '../state/user/state'
import {DEFAULT_CHR_FILTER_STATE} from '../state/chr-filter/state'

export const INITIAL_STORE: AppState = {
    auth: DEFAULT_AUTH_STATE,
    config: DEFAULT_CONFIG_STATE,
    sessionData: DEFAULT_SESSION_DATA_STATE,
    callHomeRequests: DEFAULT_CALL_HOME_REQUESTS_STATE,
    locations: DEFAULT_LOCATIONS_STATE,
    deleteCallHomeRequest: DEFAULT_DELETE_CALL_HOME_REQUEST_STATE,
    createCallHomeRequest: DEFAULT_CREATE_CALL_HOME_REQUEST_STATE,
    user: DEFAULT_USER_STATE,
    CHRFilter: DEFAULT_CHR_FILTER_STATE,
}
