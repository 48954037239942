import {useDispatch} from 'react-redux'
import {Redirect, Route, Switch, useLocation} from 'react-router-dom'
import {ChrListPage} from '../pages/chr-listing/chr-list-page'
import {ChrProvider} from '../pages/chr-listing/contexts/chr-provider'
import {SwaggerLink} from '../pages/swagger-link/swagger-link'
import {fetchCurrentUser} from '../store/state/user/action-creators'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getRedirectUrl(location: any): string {
    const {from} = location.state || {from: {pathname: '/dashboard'}}
    const url = from.search ? `${from.pathname}/${from.search}` : `${from.pathname}`
    return url
}

export function AuthenticatedRoutes(): JSX.Element {
    const dispatch = useDispatch()
    const pathname = useLocation().pathname

    if (pathname.startsWith('/login')) {
        dispatch(fetchCurrentUser())
        return <Redirect to={getRedirectUrl(location)} />
    }

    return (
        <Switch>
            <ChrProvider>
                <Route path="/list" component={ChrListPage} />
                <Route path="/swagger" component={SwaggerLink} />
                <Route path="/" render={() => <Redirect to="/list" />} />
            </ChrProvider>
        </Switch>
    )
}
