import {Terminal} from '../../../../components/terminal/terminal'
import {CallHomeRequests} from '../../../../values/call-home-request'
import {useChr} from '../../contexts/use-chr'
import {MinimiseTerminalButtonCell} from '../shared/button-cell/minimise-terminal-button-cell'
import {TabInfoCard} from './tab-info-card'
import * as Styled from './_styled/terminal-tab.styled'

interface TerminalTabProps {
    index: number
    callHomeRequest: CallHomeRequests
    breakpointMet: boolean | null
}

export function TerminalTab({
    index,
    callHomeRequest,
    breakpointMet,
}: TerminalTabProps): JSX.Element {
    const {selectedTabIndex} = useChr()
    const terminalHeight = breakpointMet ? '35vh' : '500px'

    return (
        <Styled.TabContent active={selectedTabIndex === index}>
            <Styled.TabPosition>
                <Styled.InfoLayout>
                    <TabInfoCard callHomeRequest={callHomeRequest} />
                    <MinimiseTerminalButtonCell />
                </Styled.InfoLayout>
                <Terminal terminalHeight={terminalHeight} />
            </Styled.TabPosition>
        </Styled.TabContent>
    )
}
