import {CallHomeRequests} from '../../../../values/call-home-request'
import {GuidType} from '../../../../values/generic-type-defintions'

export function chrToDelete(
    idToDelete: GuidType | null,
    chrList: CallHomeRequests[],
): CallHomeRequests | null {
    const findChr = chrList?.find((item) => item.identity === idToDelete)
    if (!findChr || !idToDelete) {
        return null
    }
    return findChr
}
