enum ActionType {
    DISPLAY_CREATE_MODAL = '[CREATE_CALL_HOME_REQUEST] DISPLAY CREATE MODAL',
    CLOSE_CREATE_MODAL = '[CREATE_CALL_HOME_REQUEST] CLOSE CREATE MODAL',
    PROCESSING_CALL_HOME_REQUEST = '[CREATE_CALL_HOME_REQUEST] PROCESSING CALL HOME REQUEST',
    CREATE_CALL_HOME_REQUEST = '[CREATE_CALL_HOME_REQUEST] CREATE CALL HOME REQUEST',
    CALL_HOME_REQUEST_CREATED = '[CREATE_CALL_HOME_REQUEST] CALL HOME REQUEST CREATED',
    SET_ERROR = '[CREATE_CALL_HOME_REQUEST] SET ERROR',
    SET_ENVIRONMENT_FILTER = '[CREATE_CALL_HOME_REQUEST] SET ENVIRONMENT FILTER',
}

export default ActionType
