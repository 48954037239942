import ActionType from './action-type'
import {Action} from './actions'
import produce from 'immer'
import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'
import {DEFAULT_USER_STATE, UserReduxState} from './state'
import LoadingState from '../../../values/loading-state-enum'

const userReducer = produce(
    (draft: UserReduxState = DEFAULT_USER_STATE, action: Action | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.GET_CURRENT_USER:
                draft.currentUserEmail = action.payload
                break
            case ActionType.REQUEST_CURRENT_USER:
                draft.isFetching = LoadingState.RequestingData
                break
            case ActionType.RECEIVE_CURRENT_USER:
                draft.isFetching = LoadingState.Loaded
                draft.user = action.payload
                break
            // case ActionType.SET_ROLES_FOR_CURRENT_USER:
            //     draft.user.role = action.payload
            //     break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_USER_STATE
                break

            /* istanbul ignore next */
            default:
                return draft
        }
        return draft
    },
)
export default userReducer
