import {useContext} from 'react'
import {ChrContext} from './chr-context'
import {UseChrResult} from './use-chr-result'
import * as ActionCreators from './state/action-creators'

export function useChr(): UseChrResult {
    const {state, dispatch} = useContext(ChrContext)

    if (state == undefined || dispatch == undefined) {
        throw new Error('useChr must be used within a ChrContext')
    }

    function toggleTerminalExpanded(isExpanded: boolean): void {
        dispatch(ActionCreators.toggleTerminalExpanded(isExpanded))
    }

    function setSelectedTabIndex(index: number): void {
        dispatch(ActionCreators.setSelectedTabIndex(index))
    }

    function setShowCloseTerminalModal(isShown: boolean): void {
        dispatch(ActionCreators.showCloseTerminalModal(isShown))
    }

    return {
        terminalExpanded: state.terminalExpanded,
        toggleTerminalExpanded,
        selectedTabIndex: state.selectedTabIndex,
        setSelectedTabIndex,
        showCloseTerminalModal: state.showCloseTerminalModal,
        setShowCloseTerminalModal,
    }
}
