import {ReactNode, ReactNodeArray} from 'react'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import * as Styles from './_styled/card-data-cell.styled'
import {CallHomeRequests} from '../../../../values/call-home-request'
import {BREAK_POINT_CARD_REDUCED_TEXT} from '../../data-helpers'

interface CardDataCellProps {
    label: string
    children?: ReactNode | ReactNodeArray
    gridArea?: string
    callHomeRequest: CallHomeRequests
    identifier: string
}
export function CardDataCell({
    label,
    children,
    gridArea,
    callHomeRequest,
    identifier,
}: CardDataCellProps): JSX.Element {
    const {width} = useDimensions()

    const id = `callHomeRequests-${identifier}_${callHomeRequest.identity}`
    return (
        <Styles.CardDataCell gridArea={gridArea} titleOnSameLine={fixedWidthReducedText(width)}>
            {label && <Styles.Label htmlFor={id}>{label}</Styles.Label>}
            <Styles.Children id={id}>{children}</Styles.Children>
        </Styles.CardDataCell>
    )
}
function fixedWidthReducedText(width: number | undefined): boolean | null {
    if (!width) {
        return null
    }
    return width > BREAK_POINT_CARD_REDUCED_TEXT
}
