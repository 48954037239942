import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import {REST} from '../../..'
import {Api} from '../../../api/api'
import {GuidType} from '../../../values/generic-type-defintions'
import {CHRStatus, UserData} from '../../../values/call-home-request'

const CALL_HOME_REQUESTS_REST_URL = 'api/v1/chrs'

export function displayCreateModal(): Actions.ConfirmCreationAction {
    return {
        type: ActionType.DISPLAY_CREATE_MODAL,
    }
}
export function closeCreateModal(): Actions.CancelCreationAction {
    return {
        type: ActionType.CLOSE_CREATE_MODAL,
    }
}
function setIsProcessingAction(): Actions.ProcessingCallHomeRequestAction {
    return {
        type: ActionType.PROCESSING_CALL_HOME_REQUEST,
    }
}
export function populateLocationCodeAction(
    locationCode: string,
): Actions.CreateCallHomeRequestAction {
    return {
        type: ActionType.CREATE_CALL_HOME_REQUEST,
        payload: locationCode,
    }
}
export function setEnvironmentAction(environment: string): Actions.SetEnvironmentFilter {
    return {
        type: ActionType.SET_ENVIRONMENT_FILTER,
        payload: environment,
    }
}
function callHomeRequestCreatedAction(
    identity: GuidType,
    portNumber: number,
    locationCode: string,
    user: UserData,
    status: CHRStatus,
    createdAt: string,
    updatedAt: string,
): Actions.CallHomeRequestCreatedAction {
    return {
        type: ActionType.CALL_HOME_REQUEST_CREATED,
        payload: {identity, portNumber, locationCode, user, status, createdAt, updatedAt},
    }
}
function setError(error: string): Actions.SetError {
    return {type: ActionType.SET_ERROR, payload: error}
}

export function createCallHomeRequest(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch, getState) => {
        dispatch(setIsProcessingAction())
        const locationCode = getState().createCallHomeRequest.locationCode
        REST.post(CALL_HOME_REQUESTS_REST_URL, {locationCode: locationCode})
            .then((response) => {
                dispatch(
                    callHomeRequestCreatedAction(
                        response.data.identity,
                        response.data.portNumber,
                        response.data.locationCode,
                        response.data.user,
                        response.data.status,
                        response.data.createdAt,
                        response.data.updatedAt,
                    ),
                )
            })
            .catch((err) => dispatch(setError(err)))
        if (true) {
            //
        }
    }
}
