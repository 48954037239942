import styled from 'styled-components'
import {ButtonStyled} from '../../../../../components/button/button.styled'
import {spacing} from '../../../../../theme/spacing'
import {showCards} from '../../../data-helpers'

export const BREAK_POINT_BUTTON_REDUCED_TEXT = 1300

interface ButtonProps {
    width: number | undefined
}

export const Button = styled(ButtonStyled)<ButtonProps>`
    justify-content: space-around;
    align-items: center;
    gap: ${spacing(1)};
    min-width: 70px;
    /* min-width: ${(props) =>
        props.width && props.width > BREAK_POINT_BUTTON_REDUCED_TEXT ? '90px' : '60px'}; */
`

interface ButtonWrapperProps {
    width?: number | undefined
}

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
    display: flex;
    gap: ${(props) => (props.width && showCards(props.width) ? `${spacing(1)}` : `${spacing(2)}`)};
`
