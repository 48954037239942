import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

interface CardDataCellProps {
    titleOnSameLine?: boolean | null
    gridArea: string | undefined
}

export const CardDataCell = styled.div<CardDataCellProps>`
    ${(props) => (props.titleOnSameLine ? 'display: flex;' : '')}
    align-items: center;
    grid-area: ${(props) => props.gridArea};
    gap: ${spacing(2)};
`

export const Label = styled.label`
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.light};
    align-items: center;
`
export const Children = styled.div`
    min-width: 100px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
