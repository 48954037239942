import {CallHomeRequests} from '../../../../values/call-home-request'
import {TableDataGridStyle, TableWrapper} from '../table/_styled/chr-list-table.styled'
import {TableRow} from '../table/table-row'
import {TerminalTab} from '../terminal-tab/terminal-tab'
import {NoDataCard} from '../cards/no-data-card/no-data-card'
import {CHRFilterReduxState} from '../../../../store/state/chr-filter/state'
import {FlexWrapper, TerminalTabWrapper} from './open-chrs.styled'
import {ListToggleWrapper} from '../table/_styled/pending-toggle.styled'
import {ChevronDown, ChevronUp} from 'react-feather'
import {useState} from 'react'

interface Props {
    openRequests: CallHomeRequests[]
    reducedOpen: CallHomeRequests[]
    breakpointMet: boolean | null
    terminalExpanded: boolean
    selectedTabIndex: number
    clickOutside: React.RefObject<HTMLDivElement>
    onlyUserCHR: boolean | CHRFilterReduxState
}

export function OpenChrs({
    openRequests,
    reducedOpen,
    breakpointMet,
    terminalExpanded,
    selectedTabIndex,
    clickOutside,
    onlyUserCHR,
}: Props): JSX.Element {
    const [activeToggleOn, setActiveToggleState] = useState(true)
    function handleClick() {
        setActiveToggleState(!activeToggleOn)
    }

    return (
        <>
            <ListToggleWrapper>
                <button onClick={handleClick}>
                    Active Connections {activeToggleOn ? <ChevronDown /> : <ChevronUp />}
                </button>
            </ListToggleWrapper>
            {activeToggleOn && (
                <>
                    {reducedOpen.length === 0 && onlyUserCHR ? (
                        <NoDataCard text={'No open call-home-requests found.'} />
                    ) : (
                        <TableWrapper breakpointMet={breakpointMet}>
                            <FlexWrapper terminalExpanded={terminalExpanded} ref={clickOutside}>
                                <TableDataGridStyle
                                    showTerminal={terminalExpanded}
                                    breakpointMet={breakpointMet}>
                                    {onlyUserCHR
                                        ? reducedOpen.map((callHomeRequest, index) => (
                                              <TableRow
                                                  key={callHomeRequest.identity}
                                                  callHomeRequest={callHomeRequest}
                                                  index={index}
                                                  active={selectedTabIndex === index}
                                              />
                                          ))
                                        : openRequests.map((callHomeRequest, index) => (
                                              <TableRow
                                                  key={callHomeRequest.identity}
                                                  callHomeRequest={callHomeRequest}
                                                  index={index}
                                                  active={selectedTabIndex === index}
                                              />
                                          ))}
                                </TableDataGridStyle>
                            </FlexWrapper>
                            <TerminalTabWrapper terminalExpanded={terminalExpanded}>
                                {onlyUserCHR
                                    ? reducedOpen.map((chr, index) => (
                                          <TerminalTab
                                              breakpointMet={breakpointMet}
                                              key={chr.identity}
                                              index={index}
                                              callHomeRequest={chr}
                                          />
                                      ))
                                    : openRequests.map((chr, index) => (
                                          <TerminalTab
                                              breakpointMet={breakpointMet}
                                              key={chr.identity}
                                              index={index}
                                              callHomeRequest={chr}
                                          />
                                      ))}
                            </TerminalTabWrapper>
                        </TableWrapper>
                    )}
                </>
            )}
        </>
    )
}
